.c-image-wrapper {
  border-radius: $global-radius;
  overflow: hidden;
  margin-bottom: 24px;

  @include bp-large {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
}

.c-section--grey-bg {
  background: $color-light-beige;
  padding-bottom: 40px;
  padding-top: 40px;
  margin-bottom: 0 !important;

  @include bp-medium {
    padding-bottom: 72px;
    padding-top: 72px;
  }

  @include bp-large {
    padding-bottom: 88px;
    padding-top: 88px;
  }
}


.u-section-margin--quick-nav {
  margin-bottom: 40px;

  @include bp-medium {
    margin-bottom: 56px;
  }

  @include bp-large {
    margin-bottom: 80px;
  }
}

.c-site-wrapper--has-page-banner-image .u-section-margin--quick-nav {
  padding-top: 40px;
  margin-top: 32px;

  @include bp-medium {
    padding-top: 80px;
    margin-top: 32px;
  }

  @include bp-large {
    padding-top: 138px;
    margin-top: 0;
  }
}

.c-section__heading {
  font-size: 40px;
  line-height: 0.9;
  margin: 24px 0;
  text-align: center;

  @include bp-medium {
    font-size: 48px;
    margin-bottom: 12px;
  }

  @include bp-large {
    font-size: 60px;
  }
}

.c-section__heading--more-margin {
  margin: 72px 0 32px;
}

.c-section__description {
  color: #4a4a4a;
  font-size: 20px;
  text-align: center;
  margin-bottom: 64px;
}

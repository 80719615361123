.c-pricing-uon-logo {
  margin-bottom: 12px;
  max-width: 100px;
  opacity: 0.25;

  @include bp-small {
    margin-bottom: 16px;
    max-width: 120px;
  }
}

.c-pricing-uon-logo-text {
  color: #a2a1a1;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;

  @include bp-small {
    font-size: 12px;
  }
}

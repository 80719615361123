.c-main {
  flex-grow: 1;
}

.c-main--home {
  text-align: center;
  
  @include bp-medium {
    text-align: left;
  }
}

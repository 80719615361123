/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-fill {
  background: $color-fill;
}

.u-color-white {
  color: $color-white;
}

.u-color-charcoal {
  color: $color-charcoal;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-charcoal;
  padding-bottom: 32px;
  padding-top: 32px;

  @include bp-medium {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @include bp-large {
    padding-bottom: 52px;
    padding-top: 84px;
  }
}

.c-footer__inner {
  @include bp($max, $bp-medium - 1px) {
    padding: 0 24px;
  }
}

.c-footer__top {
  text-align: center;

  @include bp-medium {
    text-align: left;
  }
}

.c-footer__cta-wrapper {
  margin-bottom: 24px;
  text-align: center;

  @include bp-medium {
    text-align: left;
    margin-bottom: 32px;
  }

  @include bp-large {
    margin-bottom: 0;
  }
}

.c-footer__cta-heading {
  @include bp-medium {
    margin-bottom: 16px;
  }
}

.c-footer__cta {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  white-space: nowrap;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  @include bp-medium {
    justify-content: flex-start;
  }
}

.c-footer__cta-icon {
  color: #12A5BA;
  font-size: 20px;
  margin-right: 12px;

  @include bp-medium {
    font-size: 26px;
  }
}

.c-footer__cta-text {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 600;

  @include bp-medium {
    font-size: 18px;
  }
}

.c-footer__bottom {
  margin-top: 40px;
  text-align: center;

  @include bp-medium {
    text-align: left;
  }

  @include bp-large {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.c-footer__bottom + .c-footer__bottom {
  border-top: 2px solid #3a3a3a;
  margin-top: 32px;
  padding-top: 32px;

  @include bp-small {
    margin-top: 56px;
    padding-top: 56px;
  }
}

.c-footer__about {
  margin-bottom: 32px;

  @include bp-medium {
    align-items: center;
    display: flex;
    margin-right: 48px;
  }

  @include bp-large {
    margin-bottom: 0;
    max-width: 1000px;
  }
}

.c-footer__brand {
  color: #a0a0a0;
  font-size: 20px;
  font-weight: 700;
}

.c-footer__site-description,
.c-footer__site-disclaimer {
  color: #727272;
  font-size: 14px;
  line-height: 1.4;

  @include bp-xlarge {
    font-size: 15px;
  }

  @include bp-xlarge {
    font-size: 16px;
  }
}

.c-footer__site-disclaimer {
  color: #727272;
}

.c-footer__site-description {
  margin-top: 24px;
  margin-bottom: 0;
  text-align: center;

  @include bp-medium {
    margin-top: 0;
    margin-left: 24px;
    text-align: left;
  }
}

.c-footer__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;

  @include bp-small {
    display: block;
  }
}

.c-footer__logo {
  display: block;
  opacity: 0.35;
  filter: grayscale(1);
  margin: 0 auto 24px;
  flex-shrink: 1;

  @include bp($max, $bp-small - 1px) {
    width: 33%;
  }

  @include bp-small {
    display: inline-block;
    margin-left: 24px;
    margin-bottom: 0;
  }

  &:first-child {
    @include bp-small {
      margin-left: 0;
    }
  }
}

.c-footer-navigation__pages {
  margin-bottom: 24px;
}

.c-footer__daa {
  @include bp-medium {
    flex-shrink: 0;
  }
}

.c-footer__daa-img {
  width: 146px;

  @include bp-medium {
    flex-shrink: 0;
    width: 178px;
  }
}

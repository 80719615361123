.c-accordion {
  // background-color: $color-light-beige;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 16px;
  text-align: left;

  @include bp-medium {
    margin-bottom: 24px;
  }
}

.c-accordion__trigger {
  @include justified-flexbox;
  color: #333;
  padding: 12px 40px 12px 16px;
  position: relative;
  text-align: left;
  text-decoration: none !important;
  width: 100%;

  @include bp-medium {
    padding: 16px 56px 16px 24px;
  }

  &:before {
    @extend %icomoon;
    @extend .icon-arrow-down;
    font-size: 8px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    @include bp-medium {
      right: 24px;
    }
  }

  &:after {
    background-color: #ddd;
    content: "";
    height: 1px;
    left: 5px;
    position: absolute;
    right: 5px;
    top: 100%;
  }

  &.is-expanded:after {
    left: 16px;
    right: 16px;

    @include bp-medium {
      left: 24px;
      right: 24px;
    }
  }

  &.is-expanded:before {
    transform: translateY(-50%) rotateX(180deg);
  }
}

.c-accordion__trigger-title {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;

  @include bp-medium {
    font-size: 18px;
  }
}

.c-accordion__trigger-instruction {
  font-size: 14px;
  font-weight: normal;
  margin-right: 24px;

  @include bp-medium {
    margin-right: 32px;
  }
}

.c-accordion__panel {
  outline: 0;
  padding: 24px 16px 0;
  overflow: hidden;

  @include bp-medium {
    padding: 24px 24px 0;
  }

  &.is-hidden { 
    display: none;
    height: 0;
    padding: 0;

    .no-js & {
      display: block;
      height: auto;
      padding: 24px 16px 8px;

      @include bp-medium {
        padding: 32px 24px 8px;
      }
    }
  }
}

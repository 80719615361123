.c-content-btn {
  @include bp($max, $bp-large - 1px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    width: 100%;
  }

  @include bp($max, $bp-medium - 1px) {
    max-width: 280px;
  }
}

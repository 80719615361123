/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

.u-display-block {
  display: block;
}

$utility-spacing: 1rem;

.u-margin {
  margin: $utility-spacing;
}

.u-margin-top {
  margin-top: $utility-spacing;
}

.u-margin-top-2 {
  margin-top: $utility-spacing * 2;
}

.u-margin-top-3 {
  margin-top: $utility-spacing * 3;
}

.u-margin-right {
  margin-right: $utility-spacing;
}

.u-margin-right-2 {
  margin-right: $utility-spacing * 2;
}

.u-margin-right-3 {
  margin-right: $utility-spacing * 3;
}

.u-margin-bottom {
  margin-bottom: $utility-spacing;
}

.u-margin-bottom-2 {
  margin-bottom: $utility-spacing * 2;
}

.u-margin-bottom-3 {
  margin-bottom: $utility-spacing * 3;
}

.u-margin-left {
  margin-left: $utility-spacing;
}

.u-margin-left-2 {
  margin-left: $utility-spacing * 2;
}

.u-margin-left-3 {
  margin-left: $utility-spacing * 3;
}

.u-padding {
  padding: $utility-spacing;
}

.u-padding-top {
  padding-top: $utility-spacing;
}

.u-padding-right {
  padding-right: $utility-spacing;
}

.u-padding-bottom {
  padding-bottom: $utility-spacing;
}

.u-padding-left {
  padding-left: $utility-spacing;
}

.u-margin-bottom-0 {
  margin-bottom: 0;
}

.u-section-margin {
  margin-bottom: 24px;
  margin-top: 24px;
}

.u-section-margin--medium {
  margin-bottom: 32px;
  margin-top: 32px;

  @include bp-medium {
    margin-bottom: 56px;
    margin-top: 56px;
  }

  @include bp-large {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}

.u-section-margin--large {
  margin-bottom: 56px;
  margin-top: 56px;

  @include bp-medium {
    margin-bottom: 80px;
    margin-top: 80px;
  }

  @include bp-large {
    margin-bottom: 120px;
    margin-top: 120px;
  }
}

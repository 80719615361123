.c-use-case {
  outline: 0;
  margin-top: 40px;

  @include bp-medium {
    margin-top: 72px;
  }

  @include bp-large {
    margin-top: 140px;
  }
}

.c-use-case + .c-use-case {
  margin-top: 50px;

  @include bp-large {
    margin-top: 60px;
  }

 .c-use-case__separator {
    @include bp-medium {
      border-top: 1px solid #CACACA;
      padding-top: 64px;
    }

    @include bp-large {
      padding-top: 90px;
    }
  }
}

.c-use-case__intro {
  text-align: center;

  @include bp-medium {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    text-align: left;
  }
}

.c-use-case__heading {
  color: $color-charcoal;
  font-size: 18px;
  margin-bottom: 8px;

  @include bp-medium {
    font-size: 24px;
  }

  @include bp-xlarge {
    font-size: 30px;
  }
}

.c-use-case__subheading {
  font-size: 15px;

  @include bp-medium {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.c-use-case__image {
  margin-bottom: 20px;
  max-width: 74px;

  @include bp-medium {
    margin-right: 16px;
    margin-bottom: 0;
  }

  @include bp-large {
    margin-right: 30px;
    max-width: 122px;
  }
}

.c-use-case__summary {
  line-height: 1.8;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  color: $color-text;
  font-family: $font-standard;
  font-size: 1em;
  line-height: 1.5;
  min-height: 100%;
  overflow-y: scroll;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.cookie-consent-container{
  position:fixed;
  z-index:999;
  bottom:0;
  left:0;
  width:100%;
  border:0;
  margin:0;
  padding:0;
  background:#000;
}


.cookie-consent-wrapper{
  font-size: 18px;
  font-weight: 700;
  color: #CACACA;
  max-width:1600px;
  margin:0 auto;
  padding:1.1em;
  display:flex;
  justify-content: center
}

.cookie-consent-container button {
  margin-left:0.5em;
  position: relative;
  top: -8px !important;
  line-height: 1.5em;
  font-size: 1.5em;
  background: none;
  border:none;
  color:#fff;
  cursor:pointer
}

.cookie-consent-container button:hover {
  color:#CACACA;
}

.cookie-consent-wrapper a {
  text-decoration:underline;
  color:#fff
}

.cookie-consent-container a:hover {
  color:#CACACA
}

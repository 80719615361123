/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  border-radius: $global-radius;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px;
  padding: 12px 18px 13px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform ease 200ms;
  transform: translateY(0);
  vertical-align: middle;

  @include bp-xsmall {
    padding: 12px 20px 13px;
  }

  @include bp-small {
    padding: 12px 24px 13px;
  }

  @include bp-medium {
    font-size: 16px;
    padding: 14px 28px;
  }

  @include bp-large {
    font-size: 16px;
    padding: 14px 32px;
  }

  @include bp-xlarge {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    transform: translateY(-3px);
    text-decoration: none;
  }
}

.c-btn--nowrap {
  min-width: min-content;
  white-space: nowrap;
}

.c-btn--block {
  display: block;
}

.c-btn--primary {
  background: $color-blue;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }
}

.c-btn--standard {
  background-color: $color-charcoal;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }
}

.c-btn--color-light {
  background-color: $color-light-beige;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-light-grey;
  }
}

.c-btn--color-white {
  background-color: $color-white;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-charcoal;
  }
}

.c-btn--ghost {
  background-color: transparent;
  border: 1px solid $color-charcoal;
  color: $color-charcoal;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-charcoal;
    color: $color-white;
  }
}

.c-btn--pill {
  border-radius: 3px;
  font-size: 13px;
  padding: 4px 12px 2px 12px;
}

.c-btn--small {
  padding: 4px 12px;

  @include bp-medium {
    padding: 6px 16px;
  }

  [class^="icon"] {
    display: inline-block;
    font-size: 8px;
    vertical-align: middle;
  }

  .icon-arrow-right {
    margin-left: 4px;
  }

  .icon-arrow-left {
    margin-right: 4px;
  }
}

.c-btn--large {
  padding: 14px 24px;

  @include bp-medium {
    padding: 18px 32px;
  }

  @include bp-large {
    padding: 22px 56px;
  }
}

.c-btn--massive {
  padding: 16px;

  @include bp-xsmall {
    font-size: 15px;
  }

  @include bp-medium {
    font-size: 16px;
    padding: 16px 24px;
  }

  @include bp-large {
    font-size: 18px;
    padding: 18px 24px;
  }

  @include bp-xlarge {
    padding: 20px 32px;
  }
}

.c-btn__icon {
  font-size: 22px;
  margin-right: 6px;
  transform: translateY(2px);
}

/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 3;

  @include bp-large {
    overflow: hidden;
  }
}

.c-header--ready {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0);
  transform: translateY(calc(-100% - 28px));

  .c-header__inner {
    height: 54px;

    @include bp-medium {
      height: 72px;
    }
  }
}

.c-header--fixed {
  background: $color-charcoal;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 180ms linear, box-shadow 180ms linear;
}

.c-header--pinned {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(0%);
}

.c-header__top-strip {
  align-items: center;
  background: $color-charcoal;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1800px;
  text-align: center;
  width: 100%;

  .c-header--ready & {
    display: none;
  }

  @include bp-large {
    height: 48px;
  }
}

.c-header__top-strip__copy {
  p {
    font-size: 13px;
    font-weight: 500;
    color: $color-white;
    margin-bottom: 0;

    @include bp-medium {
      font-size: 14px;
    }
    
    @include bp-large {
      font-size: 16px;
    }

    @include bp-xlarge {
      font-size: 18px;
    }
  }
}


.c-header__inner {
  align-items: center;
  background: rgba(#1e1e1e, 0.6);
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  z-index: 3;

  @include bp-large {
    background: transparent;
    padding: 0 16px;
  }

  @include bp-xlarge {
    padding: 0 24px;
    max-width: 1488px;
  }

  @include bp-full {
    padding: 0 32px;
    max-width: 1504px;
  }

  &:before {
    @include bp-large {
      background: rgba(#1e1e1e, 0.6);
      bottom: 0;
      content: "";
      left: -180px;
      margin: auto;
      max-width: 1800px;
      position: absolute;
      right: -180px;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
}

.c-header__nav {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 160px;

  @include bp-medium {
    width: 288px;
  }

  @include bp-large {
    flex-grow: 1;
    align-self: auto;
  }

  @include bp-xlarge {
    width: 440px;
  }

  .c-header--fixed & {
    @include bp-large {
      padding: 0;
    }
  }
}

.c-header__desktop-tools {
  padding: 20px 12px;
  width: 160px;

  @include bp-xsmall {
    padding: 16px 16px;
  }

  @include bp-small {
    padding: 16px 24px;
  }

  @include bp-medium {
    width: 288px;
  }

  @include bp-large {
    flex-grow: 1;
    padding: 24px 0;
  }

  @include bp-xlarge {
    width: 440px;
  }

  .c-header--fixed & {
    padding: 11px 16px;

    @include bp-small {
      padding: 11px 24px;
    }

    @include bp-medium {
      padding: 16px 24px;
    }

    @include bp-large {
    }

    @include bp-large {
      padding: 0;
    }
  }
}

.c-header__desktop-tools-pages {
  margin-bottom: 0;
  text-align: right;

  @include bp-medium {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @include bp-xlarge {
    flex-direction: row;
  }
}

.c-header__logo {
  flex-grow: 1;
}

.c-header__logo-wrapper {
  margin: 12px auto;
  position: relative;
  width: 86px;

  @include bp-medium {
    width: 96px;
  }

  @include bp-large {
    width: 132px;
  }

  .c-header--ready & {
    @include bp-large {
      width: 116px;
    }
  }

  a {
    display: block;
  }
}

.c-header__dashboard {
  color: $color-white;
  font-size: 16px;
  display: none;
  text-decoration: none;

  @include bp-medium {
    display: block;
    margin-right: 20px;
  }

  @include bp-xlarge {
    display: block;
  }

  @include bp($min, '1320px') {
    margin-right: 16px;
  }

  @include bp-full {
    margin-right: 24px;
  }

  &:hover,
  &:focus {
    color: $color-white;
    opacity: 0.8;
    text-decoration: none;
  }
}

.c-header__dashboard-icon {
  color: $color-blue;
  display: block;
  font-size: 22px;
  padding: 22px 20px 20px;

  @include bp-medium {
    padding: 0;
  }

  @include bp-xlarge {
    display: inline-block;
    font-size: 24px;
    margin-right: 4px;
    vertical-align: middle;
  }
}

.c-header__dashboard-icon .icon-dashboard {
  display: block;
  margin: auto;
}

.c-header__dashboard-text {
  display: none;
  font-weight: 600;

  @include bp-small {
    display: none;
  }

  @include bp-xlarge {
    display: inline-block;
    vertical-align: middle;
  }
}

.c-header__cta {
  box-shadow: 0 4px 8px rgba(#000, 0.33);
  display: inline-block;
  font-size: 13px;
  padding: 6px 8px;
  margin-bottom: 0;
  white-space: nowrap;

  @include bp-medium {
    display: inline-block;
    padding: 10px 14px;
  }

  @include bp($min, '840px') {
    font-size: 14px;
  }

  @include bp-large {
    font-size: 16px;
  }

  @include bp-xlarge {
    font-size: 18px;
    padding: 12px 18px;
  }

  @include bp-full {
    padding: 12px 22px;
  }
}

.c-header__cta__time {
  display: none;
  font-size: 14px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: none;
  top: calc(100% + 8px);

  @include bp-medium {
    display: block;
  }

  .c-header--ready & {
    display: none;
  }
}

.c-header__cta__desktop-text {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.c-header__cta__mobile-text {
  display: block;

  @include bp-medium {
    display: none;
  }
}

.c-header__cta__extra-mobile-text {
  display: none;

  @include bp-xsmall {
    display: inline;
  }
}

.c-main-navigation {
  background: $color-charcoal;
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  padding: 112px 24px 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  @include bp-large {
    background: transparent;
    display: block;
    height: auto;
    position: static;
    max-width: none;
    padding: 0;
  }

  &.is-visible {
    display: block;
    overflow-y: auto;
  }
}

.c-main-navigation li {
  display: block;
  margin-bottom: 12px;

  @include bp-large {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-main-navigation li a {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.8;
    text-decoration: none;
  }

  @include bp-xsmall {
    font-size: 24px;
  }

  @include bp-large {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 18px;
  }

  @include bp-xlarge {
    font-size: 17px;
    margin-right: 24px;
  }

  @include bp($min, '1400px') {
    font-size: 18px;
    margin-right: 24px;
  }
}

.c-main-navigation__mobile-tools {
  margin-top: 40px;

  @include bp-large {
    display: none;
  }
}

.c-main-navigation__pages {
  @include bp-large {
    margin-bottom: 0;
  }
}

.c-main-navigation__dashboard {
  color: $color-white;
  display: block;
  text-decoration: none;
  margin-bottom: 58px;

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
  }
}

.c-main-navigation__dashboard-icon {
  display: inline-block;
  font-size: 24px;
  margin-right: 12px;
  vertical-align: middle;

  @include bp-xsmall {
    font-size: 26px;
  }
}

.c-main-navigation__dashboard-text {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  vertical-align: middle;

  @include bp-xsmall {
    font-size: 24px;
  }
}

.c-main-navigation__cta {
  box-shadow: 0 4px 8px rgba(#000, 0.33);
  padding: 15px 24px;
  font-size: 14px;
  font-weight: bold;

  @include bp-xsmall {
    font-size: 15px;
  }
}

.c-main-navigation__long-name {
  @include bp-large {
    display: none;
  }

  @include bp-xlarge {
    display: inline;
  }
}

.c-main-navigation__short-name {
  display: none;

  @include bp-large {
    display: inline;
  }

  @include bp-xlarge {
    display: none;
  }
}

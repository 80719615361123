@charset "UTF-8";
/*
  Project: nmnt
  Author: tvou
 */
@font-face {
  font-family: HKGrotesk;
  font-weight: 400;
  font-style: normal;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 400;
  font-style: italic;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-Italic.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 500;
  font-style: normal;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 500;
  font-style: italic;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 600;
  font-style: normal;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-SemiBold.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 600;
  font-style: italic;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 700;
  font-style: normal;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-Bold.woff") format("woff");
}
@font-face {
  font-family: HKGrotesk;
  font-weight: 700;
  font-style: italic;
  src: url("/dist/assets/fonts/HKGrotesk/HKGrotesk-BoldItalic.woff") format("woff");
}
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
.c-accordion__trigger:before, [class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "icomoon";
  src: url("/dist/assets/fonts/icomoon.eot?16mev7");
  src: url("/dist/assets/fonts/icomoon.eot?16mev7#iefix") format("embedded-opentype"), url("/dist/assets/fonts/icomoon.ttf?16mev7") format("truetype"), url("/dist/assets/fonts/icomoon.woff?16mev7") format("woff"), url("/dist/assets/fonts/icomoon.svg?16mev7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon-email:before {
  content: "\e924";
}

.icon-pinterest:before {
  content: "\e925";
}

.icon-print:before {
  content: "\e926";
}

.icon-arrow-down-long:before {
  content: "\e922";
}

.icon-external-link:before {
  content: "\e923";
}

.icon-caution:before {
  content: "\e91b";
}

.icon-chatbot:before {
  content: "\e91c";
}

.icon-download:before {
  content: "\e91d";
}

.icon-facebook:before {
  content: "\e91e";
}

.icon-help:before {
  content: "\e91f";
}

.icon-twitter:before {
  content: "\e921";
}

.icon-nav-cross:before {
  content: "\e91a";
}

.icon-arrow-down:before, .c-accordion__trigger:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e903";
}

.icon-bbq:before {
  content: "\e904";
}

.icon-beef:before {
  content: "\e905";
}

.icon-blender:before {
  content: "\e906";
}

.icon-chicken:before {
  content: "\e907";
}

.icon-cross:before {
  content: "\e908";
}

.icon-dairy:before {
  content: "\e909";
}

.icon-dashboard:before {
  content: "\e90a";
}

.icon-fish:before {
  content: "\e90b";
}

.icon-fruit:before {
  content: "\e90c";
}

.icon-frying-pan:before {
  content: "\e90d";
}

.icon-grains:before {
  content: "\e90e";
}

.icon-info:before {
  content: "\e90f";
}

.icon-magnifying-glass:before {
  content: "\e910";
}

.icon-microwave:before {
  content: "\e911";
}

.icon-oven:before {
  content: "\e912";
}

.icon-plus:before {
  content: "\e913";
}

.icon-pork:before {
  content: "\e914";
}

.icon-pot:before {
  content: "\e915";
}

.icon-sandwich-press:before {
  content: "\e916";
}

.icon-slow-cooker:before {
  content: "\e917";
}

.icon-tick:before {
  content: "\e918";
}

.icon-vegetables:before {
  content: "\e919";
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif; /* 1 */
  line-height: 1.15; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.5rem;
}

address,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  font-size: 0.9375em;
}
@media (min-width: 480px) {
  address,
  blockquote, p, pre,
  dl, ol, ul,
  figure,
  hr,
  table,
  fieldset {
    font-size: 1em;
  }
}
@media (min-width: 1024px) {
  address,
  blockquote, p, pre,
  dl, ol, ul,
  figure,
  hr,
  table,
  fieldset {
    font-size: 1.0625em;
  }
}
address p,
blockquote p, p p, pre p,
dl p, ol p, ul p,
figure p,
hr p,
table p,
fieldset p {
  font-size: inherit;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child:before {
  content: open-quote;
}
blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #15A5BA;
  font-family: "HKGrotesk", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong,
.h1 strong, .h2 strong, .h3 strong, .h4 strong, .h5 strong, .h6 strong {
  font-weight: 700;
}

h1, .h1 {
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 2rem;
  }
}
@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}
@media (min-width: 1280px) {
  h2, .h2 {
    font-size: 2.1875rem;
  }
}

h3, .h3 {
  font-size: 1.1875rem;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1280px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}
@media (min-width: 1280px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.0625rem;
}
@media (min-width: 768px) {
  h5, .h5 {
    font-size: 1.125rem;
  }
}
@media (min-width: 1280px) {
  h5, .h5 {
    font-size: 1.25rem;
  }
}

h6, .h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  color: #313131;
  font-family: "HKGrotesk", sans-serif;
  font-size: 1em;
  line-height: 1.5;
  min-height: 100%;
  overflow-y: scroll;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.cookie-consent-container {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  background: #000;
}

.cookie-consent-wrapper {
  font-size: 18px;
  font-weight: 700;
  color: #CACACA;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1.1em;
  display: flex;
  justify-content: center;
}

.cookie-consent-container button {
  margin-left: 0.5em;
  position: relative;
  top: -8px !important;
  line-height: 1.5em;
  font-size: 1.5em;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.cookie-consent-container button:hover {
  color: #CACACA;
}

.cookie-consent-wrapper a {
  text-decoration: underline;
  color: #fff;
}

.cookie-consent-container a:hover {
  color: #CACACA;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] { /* [1] */
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #15A5BA;
  font-weight: bold;
  text-decoration: none;
}
a:hover {
  color: #15A5BA;
  text-decoration: underline;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

.o-flex {
  display: flex;
}

/* ==========================================================================
   #GRID
   ========================================================================== */
.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}
@media (min-width: 768px) {
  .o-grid {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.o-grid--no-wrap {
  flex-wrap: nowrap;
}

.o-grid--scroll {
  overflow-x: scroll;
}
@media (max-width: 767px) {
  .o-inner-wrapper .o-grid--scroll {
    margin-left: -8px;
    margin-right: -16px;
  }
}

@media (min-width: 768px) {
  .o-grid--reverse-at-medium {
    flex-direction: row-reverse;
  }
}

.o-grid--small--gutter {
  margin-left: -12px;
  margin-right: -12px;
}
@media (min-width: 768px) {
  .o-grid--small--gutter {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.o-grid--medium--gutter {
  margin-left: -16px;
  margin-right: -16px;
}
@media (min-width: 768px) {
  .o-grid--medium--gutter {
    margin-left: -32px;
    margin-right: -32px;
  }
}

.o-grid__cell {
  padding: 0 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .o-grid__cell {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .o-grid--no-mb--large .o-grid__cell {
    margin-bottom: 0;
  }
}

.o-grid__cell--small-gutter {
  padding: 0 12px;
}
@media (min-width: 768px) {
  .o-grid__cell--small-gutter {
    padding: 0 24px;
  }
}

.o-grid__cell--medium-gutter {
  padding: 0 16px;
}
@media (min-width: 768px) {
  .o-grid__cell--medium-gutter {
    padding: 0 32px;
  }
}

.o-grid__cell--wide-gutter {
  padding: 0 24px;
}
@media (min-width: 768px) {
  .o-grid__cell--wide-gutter {
    padding: 0 32px;
  }
}

.o-grid__cell--no-flex {
  display: block;
}

@media (min-width: 1024px) {
  .o-grid__cell--left-copy {
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .o-grid__cell--right-copy {
    padding-left: 0;
  }
}

.o-grid__cell--20 {
  flex-basis: 20%;
  max-width: 20%;
}

.o-grid__cell--25 {
  flex-basis: 25%;
  max-width: 25%;
}

.o-grid__cell--33 {
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.o-grid__cell--40 {
  flex-basis: 40%;
  max-width: 40%;
}

.o-grid__cell--45 {
  flex-basis: 45%;
  max-width: 45%;
}

.o-grid__cell--50 {
  flex-basis: 50%;
  max-width: 50%;
}

.o-grid__cell--55 {
  flex-basis: 55%;
  max-width: 55%;
}

.o-grid__cell--60 {
  flex-basis: 60%;
  max-width: 60%;
}

.o-grid__cell--66 {
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.o-grid__cell--75 {
  flex-basis: 75%;
  max-width: 75%;
}

.o-grid__cell--100 {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 480px) {
  .o-grid__cell--20-at-small {
    flex-basis: 20%;
    max-width: 20%;
  }
  .o-grid__cell--25-at-small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-grid__cell--33-at-small {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .o-grid__cell--40-at-small {
    flex-basis: 40%;
    max-width: 40%;
  }
  .o-grid__cell--45-at-small {
    flex-basis: 45%;
    max-width: 45%;
  }
  .o-grid__cell--50-at-small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-grid__cell--55-at-small {
    flex-basis: 55%;
    max-width: 55%;
  }
  .o-grid__cell--60-at-small {
    flex-basis: 60%;
    max-width: 60%;
  }
  .o-grid__cell--66-at-small {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .o-grid__cell--75-at-small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-grid__cell--100-at-small {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .o-grid__cell--20-at-medium {
    flex-basis: 20%;
    max-width: 20%;
  }
  .o-grid__cell--25-at-medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-grid__cell--33-at-medium {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .o-grid__cell--40-at-medium {
    flex-basis: 40%;
    max-width: 40%;
  }
  .o-grid__cell--45-at-medium {
    flex-basis: 45%;
    max-width: 45%;
  }
  .o-grid__cell--50-at-medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-grid__cell--55-at-medium {
    flex-basis: 55%;
    max-width: 55%;
  }
  .o-grid__cell--60-at-medium {
    flex-basis: 60%;
    max-width: 60%;
  }
  .o-grid__cell--66-at-medium {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .o-grid__cell--75-at-medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-grid__cell--100-at-medium {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .o-grid__cell--20-at-large {
    flex-basis: 20%;
    max-width: 20%;
  }
  .o-grid__cell--25-at-large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-grid__cell--33-at-large {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .o-grid__cell--40-at-large {
    flex-basis: 40%;
    max-width: 40%;
  }
  .o-grid__cell--45-at-large {
    flex-basis: 45%;
    max-width: 45%;
  }
  .o-grid__cell--50-at-large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-grid__cell--55-at-large {
    flex-basis: 55%;
    max-width: 55%;
  }
  .o-grid__cell--60-at-large {
    flex-basis: 60%;
    max-width: 60%;
  }
  .o-grid__cell--66-at-large {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .o-grid__cell--75-at-large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-grid__cell--100-at-large {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1280px) {
  .o-grid__cell--20-at-xlarge {
    flex-basis: 20%;
    max-width: 20%;
  }
  .o-grid__cell--25-at-xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-grid__cell--33-at-xlarge {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .o-grid__cell--40-at-xlarge {
    flex-basis: 40%;
    max-width: 40%;
  }
  .o-grid__cell--45-at-xlarge {
    flex-basis: 45%;
    max-width: 45%;
  }
  .o-grid__cell--50-at-xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-grid__cell--55-at-xlarge {
    flex-basis: 55%;
    max-width: 55%;
  }
  .o-grid__cell--60-at-xlarge {
    flex-basis: 60%;
    max-width: 60%;
  }
  .o-grid__cell--66-at-xlarge {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .o-grid__cell--75-at-xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-grid__cell--100-at-xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .o-grid__cell--20-at-full {
    flex-basis: 20%;
    max-width: 20%;
  }
  .o-grid__cell--25-at-full {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-grid__cell--33-at-full {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .o-grid__cell--40-at-full {
    flex-basis: 40%;
    max-width: 40%;
  }
  .o-grid__cell--45-at-full {
    flex-basis: 45%;
    max-width: 45%;
  }
  .o-grid__cell--50-at-full {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-grid__cell--55-at-full {
    flex-basis: 55%;
    max-width: 55%;
  }
  .o-grid__cell--60-at-full {
    flex-basis: 60%;
    max-width: 60%;
  }
  .o-grid__cell--66-at-full {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .o-grid__cell--75-at-full {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-grid__cell--100-at-full {
    flex-basis: 100%;
    max-width: 100%;
  }
}
/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

table th {
  background-color: #f3f3f3;
  border: 1px solid #000;
  font-weight: bold;
  min-width: 190px;
  padding: 12px 16px;
  vertical-align: middle;
}
table td {
  border: 1px solid #000;
  padding: 12px 16px;
}
table tbody tr {
  vertical-align: top;
}

.c-table-wrapper {
  margin-left: -16px;
  margin-right: 16px;
  overflow: scroll;
  padding: 0 16px;
  width: calc(100% + 32px);
}

.c-pricing-table {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  margin: 0 auto 40px;
  max-width: 724px;
}
@media (min-width: 480px) {
  .c-pricing-table {
    font-size: 14px;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .c-pricing-table {
    font-size: 17px;
  }
}
.c-pricing-table th {
  min-width: 0;
}
.c-pricing-table th, .c-pricing-table td {
  border-width: 0;
  padding: 12px 10px;
}
@media (min-width: 480px) {
  .c-pricing-table th, .c-pricing-table td {
    padding: 12px 16px;
  }
}
@media (min-width: 768px) {
  .c-pricing-table th, .c-pricing-table td {
    padding: 12px 24px;
  }
}
.c-pricing-table td {
  background: #fff;
}
.c-pricing-table th {
  background: #15A5BA;
  color: #fff;
  font-weight: bold;
}
.c-pricing-table tr:nth-child(odd) td {
  background: #FAFAFA;
}
.c-pricing-table tr td:first-child {
  background: #91DCE9;
  font-weight: bold;
}

@media (min-width: 768px) {
  .c-pricing-table__col-qty {
    width: 21%;
  }
}

@media (min-width: 768px) {
  .c-pricing-table__col-unit {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .c-pricing-table__col-gst {
    width: 21%;
  }
}

@media (min-width: 768px) {
  .c-pricing-table__col-price {
    width: 33%;
  }
}

.c-pricing-table__row-break {
  display: block;
  height: 0;
}
@media (min-width: 768px) {
  .c-pricing-table__row-break {
    display: none;
  }
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  max-width: 1800px;
}
.o-wrapper:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-inner-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1488px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .o-inner-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.o-inner-wrapper--narrower {
  max-width: 948px;
}

.c-abstract {
  font-size: 20px;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .c-abstract {
    font-size: 24px;
    margin-bottom: 32px;
  }
}

.c-abstract--margin {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .c-abstract--margin {
    margin-bottom: 56px;
  }
}

.c-accordion {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 16px;
  text-align: left;
}
@media (min-width: 768px) {
  .c-accordion {
    margin-bottom: 24px;
  }
}

.c-accordion__trigger {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #333;
  padding: 12px 40px 12px 16px;
  position: relative;
  text-align: left;
  text-decoration: none !important;
  width: 100%;
}
@media (min-width: 768px) {
  .c-accordion__trigger {
    padding: 16px 56px 16px 24px;
  }
}
.c-accordion__trigger:before {
  font-size: 8px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .c-accordion__trigger:before {
    right: 24px;
  }
}
.c-accordion__trigger:after {
  background-color: #ddd;
  content: "";
  height: 1px;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 100%;
}
.c-accordion__trigger.is-expanded:after {
  left: 16px;
  right: 16px;
}
@media (min-width: 768px) {
  .c-accordion__trigger.is-expanded:after {
    left: 24px;
    right: 24px;
  }
}
.c-accordion__trigger.is-expanded:before {
  transform: translateY(-50%) rotateX(180deg);
}

.c-accordion__trigger-title {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .c-accordion__trigger-title {
    font-size: 18px;
  }
}

.c-accordion__trigger-instruction {
  font-size: 14px;
  font-weight: normal;
  margin-right: 24px;
}
@media (min-width: 768px) {
  .c-accordion__trigger-instruction {
    margin-right: 32px;
  }
}

.c-accordion__panel {
  outline: 0;
  padding: 24px 16px 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .c-accordion__panel {
    padding: 24px 24px 0;
  }
}
.c-accordion__panel.is-hidden {
  display: none;
  height: 0;
  padding: 0;
}
.no-js .c-accordion__panel.is-hidden {
  display: block;
  height: auto;
  padding: 24px 16px 8px;
}
@media (min-width: 768px) {
  .no-js .c-accordion__panel.is-hidden {
    padding: 32px 24px 8px;
  }
}

.c-article-card {
  background-color: #fff;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  transition: box-shadow ease 250ms;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-article-card {
    display: block;
    min-height: 1px;
  }
}
.c-article-card:hover, .c-article-card:focus {
  box-shadow: 0 8px 24px -4px #e3e3e3;
  text-decoration: none;
}

.c-article-card__content {
  background-color: #f3f3f3;
  border-bottom: 3px solid #fff;
  flex-grow: 1;
  padding: 16px 24px;
}
@media (min-width: 768px) {
  .c-article-card__content {
    padding: 24px;
  }
}

.c-article-card__subtitle {
  color: #15A5BA;
  font-weight: bold;
  line-height: 1;
  font-size: 13px;
  margin-bottom: 12px;
}

.c-article-card__stats {
  align-items: center;
  background-color: #f3f3f3;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #807f7f;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 16px 20px;
  text-align: left;
}
@media (min-width: 768px) {
  .c-article-card__stats {
    padding: 16px 24px;
  }
}

.c-article-card__cta {
  color: #15A5BA;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 480px) {
  .c-article-card__cta {
    font-size: 18px;
  }
}

.c-article-card__heading {
  font-family: "HKGrotesk", sans-serif;
  text-transform: none;
  color: #313131;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .c-article-card__heading {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .c-article-card__heading {
    font-size: 25px;
  }
}

.c-article-card__image {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: auto;
  width: 100%;
}

.c-article-card__top {
  background-color: #fff;
  position: relative;
  text-align: right;
}

.c-article-card__tag {
  background-color: #15A5BA;
  border-top-right-radius: 0;
  color: #fff;
  top: 0;
  right: 0;
  font-size: 13px;
  font-weight: bold;
  padding: 4px 8px;
  position: absolute;
  text-transform: uppercase;
}
.c-article-card__tag:first-child {
  position: static;
}

.c-article-card__time {
  color: #807f7f;
  font-size: 15px;
  font-weight: 500;
}

.c-article-category {
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

.c-article__header-image {
  display: block;
  margin-bottom: 24px;
}

.c-article__subtitle {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .c-article__subtitle {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.c-article__title {
  font-size: 24px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .c-article__title {
    font-size: 40px;
    margin-bottom: 24px;
  }
}

.c-article__abstract {
  font-size: 16px;
  line-height: 1.78;
  margin-bottom: 16px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .c-article__abstract {
    font-size: 22px;
    margin-bottom: 56px;
  }
}

.c-article__content {
  position: relative;
  margin-left: auto;
  margin-bottom: 48px;
  margin-right: auto;
  max-width: 640px;
}
@media (min-width: 1024px) {
  .c-article__content {
    margin-bottom: 96px;
    max-width: 720px;
  }
}
.c-article__content h1, .c-article__content h2, .c-article__content h3, .c-article__content h4, .c-article__content h5, .c-article__content h6 {
  font-family: "HKGrotesk", sans-serif;
  text-transform: none;
  font-weight: 700;
}
.c-article__content h1 strong, .c-article__content h2 strong, .c-article__content h3 strong, .c-article__content h4 strong, .c-article__content h5 strong, .c-article__content h6 strong {
  font-weight: 700;
}

.c-articles-index-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-articles-index-category {
  align-items: center;
  border-radius: 56px;
  border: 1px solid #a7a7a7;
  color: #a7a7a7;
  display: inline-block;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  padding: 6px 16px;
  margin: 0 4px 16px;
  text-align: center;
  text-decoration: none;
}
.c-articles-index-category:hover, .c-articles-index-category:focus {
  color: #313131;
  text-decoration: none;
}
@media (min-width: 480px) {
  .c-articles-index-category {
    font-size: 18px;
    margin: 0 8px 16px;
    padding: 6px 24px;
  }
}
@media (min-width: 768px) {
  .c-articles-index-category {
    font-size: 20px;
    margin: 0 10px 16px;
    min-width: 148px;
    padding: 10px 32px;
  }
}
@media (min-width: 1024px) {
  .c-articles-index-category {
    min-width: 172px;
    padding: 10px 32px;
  }
}
@media (min-width: 768px) {
  .c-articles-index-category:first-child {
    min-width: 120px;
  }
}
@media (min-width: 1024px) {
  .c-articles-index-category:first-child {
    min-width: 144px;
  }
}
.c-recipes-preview__tabs.is-visible .c-articles-index-category {
  opacity: 1;
}

.c-articles-index-category--active {
  background: #15A5BA;
  border-color: #15A5BA;
  color: #fff;
  opacity: 1;
  position: relative;
  z-index: 1;
}
.c-articles-index-category--active:hover, .c-articles-index-category--active:focus {
  color: #fff;
}

.c-articles-search {
  display: flex;
  justify-content: center;
  margin: 32px auto;
  max-width: 600px;
  min-width: 260px;
  position: relative;
  width: 100%;
}
@media (min-width: 480px) {
  .c-articles-search {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .c-articles-search {
    margin-bottom: 48px;
  }
}
@media (min-width: 1024px) {
  .c-articles-search {
    margin-bottom: 64px;
  }
}

.c-articles-search__input {
  border: 1px solid #c1c1c1;
  border-right-width: 0;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #313131;
  flex-grow: 1;
  font-size: 16px;
  height: 48px;
  min-width: 0;
  padding: 8px 12px;
  flex: 1;
}
@media (min-width: 768px) {
  .c-articles-search__input {
    padding: 8px 16px;
    font-size: 20px;
  }
}
.c-articles-search__input::placeholder {
  color: #cac6c0;
}
.c-articles-search__input::-ms-clear {
  display: none;
}

.c-articles-search__button {
  background: #15A5BA;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 48px;
  line-height: 0;
  padding: 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 48px;
  z-index: 1;
}
.c-articles-search__button:hover, .c-articles-search__button:focus, .c-articles-search__button:active {
  color: #fff;
}

.c-articles-search__reset {
  font-size: 13px;
  position: absolute;
  right: 64px;
  text-decoration: none;
  text-transform: uppercase;
  top: 15px;
}

.c-benefit {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 16px;
  max-width: 280px;
  width: 100%;
}
@media (min-width: 768px) {
  .c-benefit {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .c-benefit {
    justify-content: flex-start;
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .c-benefit {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .c-benefit--light {
    max-width: 300px;
  }
}

.c-benefit__icon {
  color: #15A5BA;
  display: block;
  flex-shrink: 0;
  font-size: 28px;
  line-height: 1;
  margin-right: 16px;
  text-align: center;
  width: 30px;
}
@media (min-width: 768px) {
  .c-benefit__icon {
    font-size: 40px;
    width: 50px;
  }
}
@media (min-width: 768px) {
  .c-benefit--light .c-benefit__icon {
    font-size: 34px;
    width: 40px;
  }
}

.c-benefit__heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .c-benefit__heading {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .c-benefit__heading {
    font-size: 18px;
  }
}
.c-benefit--light .c-benefit__heading {
  font-weight: 400;
}

.c-benefits-panel {
  background-color: #f3f3f3;
  padding-bottom: 32px;
  padding-top: 32px;
  text-align: center;
}
@media (min-width: 768px) {
  .c-benefits-panel {
    padding-bottom: 56px;
    padding-top: 56px;
  }
}
@media (min-width: 1024px) {
  .c-benefits-panel {
    padding-bottom: 72px;
    padding-top: 72px;
    text-align: left;
  }
}

.c-benefits-panel__content {
  max-width: 700px;
}
@media (min-width: 1024px) {
  .c-benefits-panel__content {
    max-width: 1448px;
  }
}

.c-benefits-panel__cell {
  flex-basis: 100%;
  margin-bottom: 24px;
  max-width: 100%;
}
@media (min-width: 768px) {
  .c-benefits-panel__cell {
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .c-benefits-panel__cell--image {
    flex-basis: 45%;
    max-width: 45%;
  }
}

@media (min-width: 1024px) {
  .c-benefits-panel__cell--content {
    flex-basis: 55%;
    max-width: 55%;
  }
}

.c-benefits-panel__heading {
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .c-benefits-panel__heading {
    margin-bottom: 24px;
  }
}

.c-benefits-panel__img {
  display: block;
  margin: 0 auto;
}

.c-benefits-panel__copy {
  font-size: 16px;
  margin-bottom: 24px;
}
@media (min-width: 1280px) {
  .c-benefits-panel__copy {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .c-benefits-panel__bottom {
    display: block;
  }
}
@media (min-width: 1280px) {
  .c-benefits-panel__bottom {
    align-items: center;
    display: flex;
  }
}

.c-btn.c-benefits-panel__cta {
  margin-bottom: 16px;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .c-btn.c-benefits-panel__cta {
    margin-bottom: 24px;
    margin-right: 24px;
  }
}

.c-benefits-panel__summary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-benefits-panel__summary-copy {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin-left: 16px;
  max-width: 360px;
  text-align: left;
}
@media (min-width: 768px) {
  .c-benefits-panel__summary-copy {
    font-size: 15px;
    max-width: none;
    margin-left: 16px;
  }
}

.c-benefits-panel__summary-img {
  max-width: 38px;
}
@media (min-width: 1024px) {
  .c-benefits-panel__summary-img {
    max-width: 44px;
  }
}
@media (min-width: 1280px) {
  .c-benefits-panel__summary-img {
    max-width: 56px;
  }
}
@media (min-width: 1600px) {
  .c-benefits-panel__summary-img {
    max-width: 68px;
  }
}

.c-btn-row {
  display: block;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .c-btn-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.c-btn-row__btn {
  margin-bottom: 32px !important;
}
@media (min-width: 768px) {
  .c-btn-row__btn {
    margin-bottom: 0 !important;
  }
}

.c-btn-row__image {
  max-height: 40px;
  margin-left: 16px;
}
@media (min-width: 768px) {
  .c-btn-row__image {
    max-height: 50px;
  }
}
@media (min-width: 1024px) {
  .c-btn-row__image {
    margin-left: 24px;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.c-btn {
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px;
  padding: 12px 18px 13px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform ease 200ms;
  transform: translateY(0);
  vertical-align: middle;
}
@media (min-width: 375px) {
  .c-btn {
    padding: 12px 20px 13px;
  }
}
@media (min-width: 480px) {
  .c-btn {
    padding: 12px 24px 13px;
  }
}
@media (min-width: 768px) {
  .c-btn {
    font-size: 16px;
    padding: 14px 28px;
  }
}
@media (min-width: 1024px) {
  .c-btn {
    font-size: 16px;
    padding: 14px 32px;
  }
}
@media (min-width: 1280px) {
  .c-btn {
    font-size: 18px;
  }
}
.c-btn:hover, .c-btn:focus {
  transform: translateY(-3px);
  text-decoration: none;
}

.c-btn--nowrap {
  min-width: min-content;
  white-space: nowrap;
}

.c-btn--block {
  display: block;
}

.c-btn--primary {
  background: #15A5BA;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  color: #fff;
}

.c-btn--standard {
  background-color: #313131;
}
.c-btn--standard, .c-btn--standard:hover, .c-btn--standard:active, .c-btn--standard:focus {
  color: #fff;
}

.c-btn--color-light {
  background-color: #f3f3f3;
}
.c-btn--color-light, .c-btn--color-light:hover, .c-btn--color-light:active, .c-btn--color-light:focus {
  color: #838383;
}

.c-btn--color-white {
  background-color: #fff;
}
.c-btn--color-white, .c-btn--color-white:hover, .c-btn--color-white:active, .c-btn--color-white:focus {
  color: #313131;
}

.c-btn--ghost {
  background-color: transparent;
  border: 1px solid #313131;
  color: #313131;
}
.c-btn--ghost:hover, .c-btn--ghost:active, .c-btn--ghost:focus {
  background-color: #313131;
  color: #fff;
}

.c-btn--pill {
  border-radius: 3px;
  font-size: 13px;
  padding: 4px 12px 2px 12px;
}

.c-btn--small {
  padding: 4px 12px;
}
@media (min-width: 768px) {
  .c-btn--small {
    padding: 6px 16px;
  }
}
.c-btn--small [class^=icon] {
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}
.c-btn--small .icon-arrow-right {
  margin-left: 4px;
}
.c-btn--small .icon-arrow-left {
  margin-right: 4px;
}

.c-btn--large {
  padding: 14px 24px;
}
@media (min-width: 768px) {
  .c-btn--large {
    padding: 18px 32px;
  }
}
@media (min-width: 1024px) {
  .c-btn--large {
    padding: 22px 56px;
  }
}

.c-btn--massive {
  padding: 16px;
}
@media (min-width: 375px) {
  .c-btn--massive {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .c-btn--massive {
    font-size: 16px;
    padding: 16px 24px;
  }
}
@media (min-width: 1024px) {
  .c-btn--massive {
    font-size: 18px;
    padding: 18px 24px;
  }
}
@media (min-width: 1280px) {
  .c-btn--massive {
    padding: 20px 32px;
  }
}

.c-btn__icon {
  font-size: 22px;
  margin-right: 6px;
  transform: translateY(2px);
}

.c-collapsible {
  outline: 0;
  display: none;
}
.c-collapsible.is-visible {
  display: block;
}
.no-js .c-collapsible {
  display: block;
}

@media (max-width: 1023px) {
  .c-content-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .c-content-btn {
    max-width: 280px;
  }
}

.c-featured-outlet {
  padding: 0 32px;
}
@media (min-width: 375px) {
  .c-featured-outlet {
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .c-featured-outlet {
    display: table;
    padding: 0;
    width: 100%;
  }
}

.c-featured-outlet__outlet {
  margin-bottom: 24px;
  text-align: center;
}
@media (min-width: 1024px) {
  .c-featured-outlet__outlet {
    display: table-cell;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .c-featured-outlet__outlet {
    padding-right: 32px;
  }
}
@media (min-width: 1280px) {
  .c-featured-outlet__outlet {
    padding-right: 40px;
  }
}

.c-featured-outlet__outlet:last-child {
  padding-right: 0;
}
@media (min-width: 1024px) {
  .c-featured-outlet__outlet:last-child {
    padding-right: 32px;
  }
}

.c-featured-outlet__outlet-img {
  width: 100%;
}

.features-and-pricing table {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;
}

.features-and-pricing table td, .features-and-pricing table th {
  border: 0;
}

.features-and-pricing thead th {
  background-color: #999;
  color: #fff;
}

.features-and-pricing thead td {
  text-align: center;
  vertical-align: top;
}

.features-and-pricing thead th b {
  font-size: 1.2em;
}

.features-and-pricing thead td small {
  display: block;
}

.features-and-pricing tbody th {
  text-align: left;
  max-width: 190px;
}

.features-and-pricing tbody td {
  text-align: center;
}

.features-and-pricing tbody td.col-disabled {
  vertical-align: center;
}

.features-and-pricing tbody tr:nth-child(odd) td {
  background: #fafafa;
}

.features-and-pricing abbr {
  text-decoration: none;
}

.features-and-pricing abbr.on {
  color: green;
}

.features-and-pricing abbr.off {
  color: red;
  opacity: 0.4;
  font-size: 0.6em;
}

.features-and-pricing tfoot td {
  background-color: #657786;
  color: #fff;
  text-align: center;
}

.features-and-pricing tfoot td p {
  text-align: center;
}

.features-and-pricing tfoot td a {
  color: #fff;
}

.features-and-pricing fieldset.price-config {
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 1em auto 1em auto;
}

.features-and-pricing fieldset.price-config legend {
  text-align: center;
  width: auto;
  font-weight: bold;
}

/* FF only */
@-moz-document url-prefix() {
  .features-and-pricing fieldset.price-config {
    position: relative;
  }
  .features-and-pricing fieldset.price-config legend {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.features-and-pricing fieldset.price-config label {
  margin: 0.5em auto 0.5em auto;
}

.features-and-pricing fieldset.price-config input[type=range] {
  text-align: center;
  width: 100%;
  display: block;
  margin: 0.5em auto 0.5em auto;
}

.features-and-pricing fieldset.price-config input[type=number] {
  text-align: center;
  display: block;
  margin: 0.5em auto 0 auto;
  font-size: 2em;
  font-weight: bold;
  border: none;
  width: 100%;
}

.features-and-pricing fieldset.price-config input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
}

.features-and-pricing .c-accordion__trigger {
  padding: 0 28px 0 0;
}

.features-and-pricing .c-accordion__trigger:before {
  right: 0;
}

.features-and-pricing .c-accordion__trigger:after {
  display: none;
}

.features-and-pricing .c-accordion__panel {
  padding: 8px 0 0 0;
}

.c-figure__img {
  margin-bottom: 12px;
}
.c-article .c-figure__img {
  width: 100%;
}

.c-figure__caption {
  font-style: italic;
}

.c-footer-navigation {
  text-align: center;
}
@media (min-width: 375px) {
  .c-footer-navigation {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .c-footer-navigation {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}
.c-footer-navigation li {
  display: block;
  margin-bottom: 12px;
}
@media (min-width: 375px) {
  .c-footer-navigation li {
    float: left;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .c-footer-navigation li {
    display: inline-block;
    float: none;
    margin-right: 20px;
    width: auto;
  }
}
@media (min-width: 1280px) {
  .c-footer-navigation li {
    margin-right: 40px;
  }
}
.c-footer-navigation li a {
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
@media (min-width: 375px) {
  .c-footer-navigation li a {
    font-size: 13px;
  }
}
@media (min-width: 480px) {
  .c-footer-navigation li a {
    font-size: 17px;
  }
}
.c-footer-navigation li a:hover, .c-footer-navigation li a:focus {
  opacity: 0.8;
  text-decoration: none;
}

.c-footer-navigation__pages:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (min-width: 768px) {
  .c-footer-navigation__pages {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background-color: #313131;
  padding-bottom: 32px;
  padding-top: 32px;
}
@media (min-width: 768px) {
  .c-footer {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
@media (min-width: 1024px) {
  .c-footer {
    padding-bottom: 52px;
    padding-top: 84px;
  }
}

@media (max-width: 767px) {
  .c-footer__inner {
    padding: 0 24px;
  }
}

.c-footer__top {
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__top {
    text-align: left;
  }
}

.c-footer__cta-wrapper {
  margin-bottom: 24px;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__cta-wrapper {
    text-align: left;
    margin-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .c-footer__cta-wrapper {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .c-footer__cta-heading {
    margin-bottom: 16px;
  }
}

.c-footer__cta {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  white-space: nowrap;
}
.c-footer__cta:hover, .c-footer__cta:focus {
  opacity: 0.8;
}
@media (min-width: 768px) {
  .c-footer__cta {
    justify-content: flex-start;
  }
}

.c-footer__cta-icon {
  color: #12A5BA;
  font-size: 20px;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .c-footer__cta-icon {
    font-size: 26px;
  }
}

.c-footer__cta-text {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .c-footer__cta-text {
    font-size: 18px;
  }
}

.c-footer__bottom {
  margin-top: 40px;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__bottom {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .c-footer__bottom {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.c-footer__bottom + .c-footer__bottom {
  border-top: 2px solid #3a3a3a;
  margin-top: 32px;
  padding-top: 32px;
}
@media (min-width: 480px) {
  .c-footer__bottom + .c-footer__bottom {
    margin-top: 56px;
    padding-top: 56px;
  }
}

.c-footer__about {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .c-footer__about {
    align-items: center;
    display: flex;
    margin-right: 48px;
  }
}
@media (min-width: 1024px) {
  .c-footer__about {
    margin-bottom: 0;
    max-width: 1000px;
  }
}

.c-footer__brand {
  color: #a0a0a0;
  font-size: 20px;
  font-weight: 700;
}

.c-footer__site-description,
.c-footer__site-disclaimer {
  color: #727272;
  font-size: 14px;
  line-height: 1.4;
}
@media (min-width: 1280px) {
  .c-footer__site-description,
  .c-footer__site-disclaimer {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  .c-footer__site-description,
  .c-footer__site-disclaimer {
    font-size: 16px;
  }
}

.c-footer__site-disclaimer {
  color: #727272;
}

.c-footer__site-description {
  margin-top: 24px;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__site-description {
    margin-top: 0;
    margin-left: 24px;
    text-align: left;
  }
}

.c-footer__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
}
@media (min-width: 480px) {
  .c-footer__logo-wrapper {
    display: block;
  }
}

.c-footer__logo {
  display: block;
  opacity: 0.35;
  filter: grayscale(1);
  margin: 0 auto 24px;
  flex-shrink: 1;
}
@media (max-width: 479px) {
  .c-footer__logo {
    width: 33%;
  }
}
@media (min-width: 480px) {
  .c-footer__logo {
    display: inline-block;
    margin-left: 24px;
    margin-bottom: 0;
  }
}
@media (min-width: 480px) {
  .c-footer__logo:first-child {
    margin-left: 0;
  }
}

.c-footer-navigation__pages {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .c-footer__daa {
    flex-shrink: 0;
  }
}

.c-footer__daa-img {
  width: 146px;
}
@media (min-width: 768px) {
  .c-footer__daa-img {
    flex-shrink: 0;
    width: 178px;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 3;
}
@media (min-width: 1024px) {
  .c-header {
    overflow: hidden;
  }
}

.c-header--ready {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0);
  transform: translateY(calc(-100% - 28px));
}
.c-header--ready .c-header__inner {
  height: 54px;
}
@media (min-width: 768px) {
  .c-header--ready .c-header__inner {
    height: 72px;
  }
}

.c-header--fixed {
  background: #313131;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 180ms linear, box-shadow 180ms linear;
}

.c-header--pinned {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(0%);
}

.c-header__top-strip {
  align-items: center;
  background: #313131;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1800px;
  text-align: center;
  width: 100%;
}
.c-header--ready .c-header__top-strip {
  display: none;
}
@media (min-width: 1024px) {
  .c-header__top-strip {
    height: 48px;
  }
}

.c-header__top-strip__copy p {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .c-header__top-strip__copy p {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .c-header__top-strip__copy p {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .c-header__top-strip__copy p {
    font-size: 18px;
  }
}

.c-header__inner {
  align-items: center;
  background: rgba(30, 30, 30, 0.6);
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  z-index: 3;
}
@media (min-width: 1024px) {
  .c-header__inner {
    background: transparent;
    padding: 0 16px;
  }
}
@media (min-width: 1280px) {
  .c-header__inner {
    padding: 0 24px;
    max-width: 1488px;
  }
}
@media (min-width: 1600px) {
  .c-header__inner {
    padding: 0 32px;
    max-width: 1504px;
  }
}
@media (min-width: 1024px) {
  .c-header__inner:before {
    background: rgba(30, 30, 30, 0.6);
    bottom: 0;
    content: "";
    left: -180px;
    margin: auto;
    max-width: 1800px;
    position: absolute;
    right: -180px;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

.c-header__nav {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 160px;
}
@media (min-width: 768px) {
  .c-header__nav {
    width: 288px;
  }
}
@media (min-width: 1024px) {
  .c-header__nav {
    flex-grow: 1;
    align-self: auto;
  }
}
@media (min-width: 1280px) {
  .c-header__nav {
    width: 440px;
  }
}
@media (min-width: 1024px) {
  .c-header--fixed .c-header__nav {
    padding: 0;
  }
}

.c-header__desktop-tools {
  padding: 20px 12px;
  width: 160px;
}
@media (min-width: 375px) {
  .c-header__desktop-tools {
    padding: 16px 16px;
  }
}
@media (min-width: 480px) {
  .c-header__desktop-tools {
    padding: 16px 24px;
  }
}
@media (min-width: 768px) {
  .c-header__desktop-tools {
    width: 288px;
  }
}
@media (min-width: 1024px) {
  .c-header__desktop-tools {
    flex-grow: 1;
    padding: 24px 0;
  }
}
@media (min-width: 1280px) {
  .c-header__desktop-tools {
    width: 440px;
  }
}
.c-header--fixed .c-header__desktop-tools {
  padding: 11px 16px;
}
@media (min-width: 480px) {
  .c-header--fixed .c-header__desktop-tools {
    padding: 11px 24px;
  }
}
@media (min-width: 768px) {
  .c-header--fixed .c-header__desktop-tools {
    padding: 16px 24px;
  }
}
@media (min-width: 1024px) {
  .c-header--fixed .c-header__desktop-tools {
    padding: 0;
  }
}

.c-header__desktop-tools-pages {
  margin-bottom: 0;
  text-align: right;
}
@media (min-width: 768px) {
  .c-header__desktop-tools-pages {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 1280px) {
  .c-header__desktop-tools-pages {
    flex-direction: row;
  }
}

.c-header__logo {
  flex-grow: 1;
}

.c-header__logo-wrapper {
  margin: 12px auto;
  position: relative;
  width: 86px;
}
@media (min-width: 768px) {
  .c-header__logo-wrapper {
    width: 96px;
  }
}
@media (min-width: 1024px) {
  .c-header__logo-wrapper {
    width: 132px;
  }
}
@media (min-width: 1024px) {
  .c-header--ready .c-header__logo-wrapper {
    width: 116px;
  }
}
.c-header__logo-wrapper a {
  display: block;
}

.c-header__dashboard {
  color: #fff;
  font-size: 16px;
  display: none;
  text-decoration: none;
}
@media (min-width: 768px) {
  .c-header__dashboard {
    display: block;
    margin-right: 20px;
  }
}
@media (min-width: 1280px) {
  .c-header__dashboard {
    display: block;
  }
}
@media (min-width: 1320px) {
  .c-header__dashboard {
    margin-right: 16px;
  }
}
@media (min-width: 1600px) {
  .c-header__dashboard {
    margin-right: 24px;
  }
}
.c-header__dashboard:hover, .c-header__dashboard:focus {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}

.c-header__dashboard-icon {
  color: #15A5BA;
  display: block;
  font-size: 22px;
  padding: 22px 20px 20px;
}
@media (min-width: 768px) {
  .c-header__dashboard-icon {
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .c-header__dashboard-icon {
    display: inline-block;
    font-size: 24px;
    margin-right: 4px;
    vertical-align: middle;
  }
}

.c-header__dashboard-icon .icon-dashboard {
  display: block;
  margin: auto;
}

.c-header__dashboard-text {
  display: none;
  font-weight: 600;
}
@media (min-width: 480px) {
  .c-header__dashboard-text {
    display: none;
  }
}
@media (min-width: 1280px) {
  .c-header__dashboard-text {
    display: inline-block;
    vertical-align: middle;
  }
}

.c-header__cta {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
  display: inline-block;
  font-size: 13px;
  padding: 6px 8px;
  margin-bottom: 0;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .c-header__cta {
    display: inline-block;
    padding: 10px 14px;
  }
}
@media (min-width: 840px) {
  .c-header__cta {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .c-header__cta {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .c-header__cta {
    font-size: 18px;
    padding: 12px 18px;
  }
}
@media (min-width: 1600px) {
  .c-header__cta {
    padding: 12px 22px;
  }
}

.c-header__cta__time {
  display: none;
  font-size: 14px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: none;
  top: calc(100% + 8px);
}
@media (min-width: 768px) {
  .c-header__cta__time {
    display: block;
  }
}
.c-header--ready .c-header__cta__time {
  display: none;
}

.c-header__cta__desktop-text {
  display: none;
}
@media (min-width: 768px) {
  .c-header__cta__desktop-text {
    display: block;
  }
}

.c-header__cta__mobile-text {
  display: block;
}
@media (min-width: 768px) {
  .c-header__cta__mobile-text {
    display: none;
  }
}

.c-header__cta__extra-mobile-text {
  display: none;
}
@media (min-width: 375px) {
  .c-header__cta__extra-mobile-text {
    display: inline;
  }
}

.c-image-wrapper {
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .c-image-wrapper {
    margin-bottom: 0;
  }
}
.c-image-wrapper img {
  width: 100%;
}

.c-main-navigation {
  background: #313131;
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  padding: 112px 24px 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  .c-main-navigation {
    background: transparent;
    display: block;
    height: auto;
    position: static;
    max-width: none;
    padding: 0;
  }
}
.c-main-navigation.is-visible {
  display: block;
  overflow-y: auto;
}

.c-main-navigation li {
  display: block;
  margin-bottom: 12px;
}
@media (min-width: 1024px) {
  .c-main-navigation li {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-main-navigation li a {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
}
.c-main-navigation li a:hover, .c-main-navigation li a:focus {
  opacity: 0.8;
  text-decoration: none;
}
@media (min-width: 375px) {
  .c-main-navigation li a {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .c-main-navigation li a {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 18px;
  }
}
@media (min-width: 1280px) {
  .c-main-navigation li a {
    font-size: 17px;
    margin-right: 24px;
  }
}
@media (min-width: 1400px) {
  .c-main-navigation li a {
    font-size: 18px;
    margin-right: 24px;
  }
}

.c-main-navigation__mobile-tools {
  margin-top: 40px;
}
@media (min-width: 1024px) {
  .c-main-navigation__mobile-tools {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-main-navigation__pages {
    margin-bottom: 0;
  }
}

.c-main-navigation__dashboard {
  color: #fff;
  display: block;
  text-decoration: none;
  margin-bottom: 58px;
}
.c-main-navigation__dashboard:hover, .c-main-navigation__dashboard:focus, .c-main-navigation__dashboard:active {
  color: #fff;
}

.c-main-navigation__dashboard-icon {
  display: inline-block;
  font-size: 24px;
  margin-right: 12px;
  vertical-align: middle;
}
@media (min-width: 375px) {
  .c-main-navigation__dashboard-icon {
    font-size: 26px;
  }
}

.c-main-navigation__dashboard-text {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  vertical-align: middle;
}
@media (min-width: 375px) {
  .c-main-navigation__dashboard-text {
    font-size: 24px;
  }
}

.c-main-navigation__cta {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
  padding: 15px 24px;
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 375px) {
  .c-main-navigation__cta {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .c-main-navigation__long-name {
    display: none;
  }
}
@media (min-width: 1280px) {
  .c-main-navigation__long-name {
    display: inline;
  }
}

.c-main-navigation__short-name {
  display: none;
}
@media (min-width: 1024px) {
  .c-main-navigation__short-name {
    display: inline;
  }
}
@media (min-width: 1280px) {
  .c-main-navigation__short-name {
    display: none;
  }
}

.c-main {
  flex-grow: 1;
}

.c-main--home {
  text-align: center;
}
@media (min-width: 768px) {
  .c-main--home {
    text-align: left;
  }
}

.c-mobile-nav-toggle {
  background: transparent;
  color: #fff;
  border: 0;
  font-family: inherit;
  font-size: 15px;
  font-weight: 700;
  padding: 24px 16px;
  text-transform: uppercase;
  z-index: 2;
  appearance: none;
}
.c-mobile-nav-toggle.is-active {
  position: fixed;
  top: 0;
}
@media (min-width: 480px) {
  .c-mobile-nav-toggle {
    padding: 24px;
  }
}
@media (min-width: 1024px) {
  .c-mobile-nav-toggle {
    display: none;
  }
}
.c-header--ready .c-mobile-nav-toggle {
  padding: 16px 16px;
}
@media (min-width: 480px) {
  .c-header--ready .c-mobile-nav-toggle {
    padding: 16px 24px;
  }
}
@media (min-width: 768px) {
  .c-header--ready .c-mobile-nav-toggle {
    padding: 24px;
  }
}
.c-mobile-nav-toggle .icon-nav-cross {
  font-size: 22px;
  font-weight: normal;
}

.c-mobile-nav-toggle:not(:focus) {
  outline: 0;
}

.c-mobile-nav-toggle__menu {
  display: flex;
  align-items: center;
  text-align: left;
}

.c-mobile-nav-toggle__text {
  font-size: 13px;
  margin-left: 6px;
}

.c-mobile-nav-toggle__hamburger {
  display: block;
  height: 18px;
  position: relative;
  margin: 2px 0;
  width: 22px;
}

.c-mobile-nav-toggle__strokes {
  display: block;
}

.c-mobile-nav-toggle__strokes,
.c-mobile-nav-toggle__strokes:before,
.c-mobile-nav-toggle__strokes:after {
  background: #fff;
  height: 2px;
}

.c-mobile-nav-toggle__strokes:before,
.c-mobile-nav-toggle__strokes:after {
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.c-mobile-nav-toggle__strokes:before {
  top: 8px;
}

.c-mobile-nav-toggle__strokes:after {
  top: 16px;
}

.c-more-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  text-align: center;
}
@media (min-width: 768px) {
  .c-more-content {
    text-align: left;
    display: block;
    margin-top: 40px;
  }
}

.c-more-content > * {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .c-more-content > * {
    margin-bottom: 0;
  }
}

.c-more-content__instruction {
  color: #313131;
  font-size: 13px;
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .c-more-content__instruction {
    display: inline-block;
    margin-right: 16px;
  }
}
.c-superfood-carousel .c-more-content__instruction {
  color: #fff;
}

.c-more-content .c-btn {
  display: inline-block;
}
@media (min-width: 768px) {
  .c-more-content .c-btn {
    margin-right: 8px;
  }
}

.c-more-copy {
  padding: 8px 0;
}

.c-other-outlets {
  background: #f7f7f7;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 14px;
}
@media (min-width: 768px) {
  .c-other-outlets {
    padding: 24px;
  }
}
@media (min-width: 1024px) {
  .c-other-outlets {
    padding: 24px 28px;
  }
}
@media (min-width: 1280px) {
  .c-other-outlets {
    padding: 24px 64px;
  }
}

.c-other-outlets__heading {
  text-align: center;
  margin-bottom: 24px;
  width: 100%;
}

.c-other-outlets__outlet {
  font-size: 14px;
  width: 50%;
}
@media (min-width: 375px) {
  .c-other-outlets__outlet {
    font-size: 16px;
  }
}
@media (min-width: 480px) {
  .c-other-outlets__outlet {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .c-other-outlets__outlet {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .c-other-outlets__outlet {
    font-size: 17px;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .c-other-outlets__outlet {
    font-size: 18px;
  }
}

.c-page-banner-wrapper {
  position: relative;
}

.c-page-banner {
  background-color: #313131;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
@media (min-width: 768px) {
  .c-page-banner {
    min-height: 380px;
  }
}
.c-page-banner-wrapper--home .c-page-banner {
  background-color: transparent;
  display: block;
}
@media (min-width: 1024px) {
  .c-page-banner-wrapper--home .c-page-banner {
    background-color: #313131;
  }
}

.c-page-banner__img {
  background-color: #313131;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  max-height: 400px;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 768px) {
  .c-page-banner__img {
    min-height: 380px;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__img {
    max-height: none;
  }
}

.c-page-banner-wrapper--home {
  padding-bottom: 0;
  margin-top: 40px;
}
@media (min-width: 1024px) {
  .c-page-banner-wrapper--home {
    margin-top: 48px;
  }
}
.c-page-banner-wrapper--home .c-page-banner {
  min-height: 360px;
}
@media (min-width: 768px) {
  .c-page-banner-wrapper--home .c-page-banner {
    min-height: 400px;
  }
}
@media (min-width: 1024px) {
  .c-page-banner-wrapper--home .c-page-banner {
    min-height: 480px;
  }
}

.c-page-banner__picture {
  display: none;
}

.c-page-banner__image {
  display: block;
  width: 100%;
}

@media (min-width: 1024px) {
  .c-page-banner__logo {
    height: 110px;
  }
}

.c-page-banner__logo-wrapper {
  margin: 0 auto;
  position: relative;
  width: 80px;
}
@media (min-width: 768px) {
  .c-page-banner__logo-wrapper {
    width: 104px;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__logo-wrapper {
    display: none;
  }
}

.c-page-banner__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  line-height: 1.35;
  margin: 0 auto;
  min-height: 300px;
  padding-bottom: 24px;
  padding-top: 104px;
  position: relative;
  text-align: center;
  z-index: 1;
}
@media (min-width: 768px) {
  .c-page-banner__content {
    min-height: 380px;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__content {
    padding-bottom: 56px;
    padding-top: 144px;
    width: 100%;
  }
}

.c-page-banner__content--home {
  display: block;
}
@media (min-width: 480px) {
  .c-page-banner__content--home {
    padding-top: 124px;
  }
}
@media (min-width: 768px) {
  .c-page-banner__content--home {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__content--home {
    padding-top: 94px;
    padding-bottom: 0;
  }
}

.c-page-banner__copy {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 auto;
  max-width: 800px;
  text-shadow: 0 0 20px #000, 0 0 20px #000;
  width: 100%;
}
.c-page-banner__copy * {
  color: #fff;
}
@media (min-width: 375px) {
  .c-page-banner__copy {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .c-page-banner__copy {
    font-size: 18px;
    margin-bottom: 24px;
  }
}
.c-page-banner__copy h1, .c-page-banner__copy h2, .c-page-banner__copy h3, .c-page-banner__copy h4, .c-page-banner__copy h6, .c-page-banner__copy h6 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .c-page-banner__copy h1, .c-page-banner__copy h2, .c-page-banner__copy h3, .c-page-banner__copy h4, .c-page-banner__copy h6, .c-page-banner__copy h6 {
    font-size: 22px;
  }
}
@media (min-width: 1280px) {
  .c-page-banner__copy h1, .c-page-banner__copy h2, .c-page-banner__copy h3, .c-page-banner__copy h4, .c-page-banner__copy h6, .c-page-banner__copy h6 {
    font-size: 28px;
    margin-bottom: 24px;
  }
}

.c-page-banner__cta {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.33);
  display: inline-block;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .c-page-banner__cta {
    margin-bottom: 32px;
  }
}

@media (min-width: 768px) {
  .c-page-banner__grid {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__grid {
    flex-direction: row;
    flex-shrink: 0;
    transform: translateY(62px);
  }
}
.c-page-banner__grid .c-page-banner__content-image {
  background: white;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  order: 2;
}
@media (min-width: 1024px) {
  .c-page-banner__grid .c-page-banner__content-image {
    order: 1;
    width: calc(50% - 42px);
  }
}
.c-page-banner__grid .c-page-banner__content-image img {
  width: 100%;
}
.c-page-banner__grid .c-page-banner__copy {
  order: 1;
}
@media (min-width: 1024px) {
  .c-page-banner__grid .c-page-banner__copy {
    margin-left: 42px;
    order: 2;
    text-align: left;
    transform: translateY(-12px);
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .c-page-banner__grid .c-page-banner__copy {
    transform: translateY(-40px);
  }
}

.c-page-banner__content-image-caption {
  color: #a2a1a1;
  font-size: 11px;
  padding: 8px 12px 8px;
}
@media (min-width: 480px) {
  .c-page-banner__content-image-caption {
    font-size: 12px;
    padding: 4px 16px 8px;
  }
}
@media (min-width: 768px) {
  .c-page-banner__content-image-caption {
    padding: 0 16px 8px;
  }
}
@media (min-width: 1024px) {
  .c-page-banner__content-image-caption {
    font-size: 13px;
  }
}

.c-page-cta {
  background: #fff;
  border-radius: 0;
  margin-bottom: 48px;
  padding: 32px;
  text-align: center;
}
.c-page-cta .c-btn {
  display: block;
}
@media (min-width: 768px) {
  .c-page-cta .c-btn {
    display: inline-block;
  }
}

.c-page-description {
  background: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: -50px auto 0 auto;
  max-width: 960px;
  padding: 16px;
  text-align: center;
  width: calc(100% - 32px);
  z-index: 2;
}
@media (min-width: 768px) {
  .c-page-description {
    font-size: 32px;
    margin: -50px auto 16px auto;
    padding: 32px;
    width: calc(100% - 48px);
  }
}

.c-page-intro {
  background-color: #fff;
  text-align: center;
}

.c-page-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 16px;
  text-shadow: 0 2px 12px #000, 0 6px 32px rgba(0, 0, 0, 0.25);
}
@media (min-width: 375px) {
  .c-page-title {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .c-page-title {
    font-size: 44px;
    margin-bottom: 20px;
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25), 0 6px 32px rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 1024px) {
  .c-page-title {
    font-size: 56px;
    margin-bottom: 24px;
  }
}
@media (min-width: 1200px) {
  .c-page-title {
    font-size: 64px;
  }
}
@media (min-width: 1600px) {
  .c-page-title {
    font-size: 68px;
  }
}

.c-page-utility {
  padding: 32px 0;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .c-page-utility {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.c-page-utility--no-pb {
  padding-bottom: 0;
}

.c-paging-link {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #807f7f;
  cursor: default;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0;
  padding: 5px 10px;
  margin: 0 2px 8px;
  min-height: 32px;
  min-width: 32px;
  text-align: center;
  text-decoration: none;
}
@media (min-width: 375px) {
  .c-paging-link {
    padding: 5px 12px;
  }
}
@media (min-width: 768px) {
  .c-paging-link {
    margin: 0 4px 8px;
  }
}
.c-paging-link[href]:hover, .c-paging-link[href]:focus {
  cursor: pointer;
  text-decoration: none;
}
.c-paging-link [class^=icon] {
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .c-paging-link .icon-arrow-left {
    margin-right: 4px;
  }
}
@media (min-width: 768px) {
  .c-paging-link .icon-arrow-right {
    margin-left: 4px;
  }
}

.c-paging-link--current {
  background: #15A5BA;
  color: #fff;
  font-weight: 700;
}

.c-paging-link--disabled {
  opacity: 0.5;
}
.c-paging-link--disabled:hover, .c-paging-link--disabled:focus {
  color: #807f7f;
}

.c-paging-link--prev {
  margin-left: 0;
}

.c-paging-link--next {
  margin-right: 0;
}

.c-paging-link__text {
  display: none;
}
@media (min-width: 768px) {
  .c-paging-link__text {
    display: inline-block;
  }
}

.c-paging-links {
  margin: 24px 0;
  text-align: left;
}
@media (min-width: 768px) {
  .c-paging-links {
    text-align: left;
  }
}

.c-paging-links--center {
  text-align: center;
}

.c-payment-methods {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 32px;
}
@media (min-width: 480px) {
  .c-payment-methods {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .c-payment-methods {
    margin-bottom: 28px;
  }
}
@media (min-width: 1024px) {
  .c-payment-methods {
    margin-bottom: 40px;
  }
}

.c-payment-methods__text {
  order: 2;
  color: #A3A3A3;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}
@media (min-width: 480px) {
  .c-payment-methods__text {
    order: 1;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.c-payment-methods__icons {
  order: 3;
}
@media (min-width: 480px) {
  .c-payment-methods__icons {
    order: 2;
  }
}

.c-payment-methods__method {
  color: #A19FA0;
  font-size: 28px;
  margin: 0 2px;
}
@media (min-width: 768px) {
  .c-payment-methods__method {
    font-size: 36px;
  }
}

.c-payment-methods__cta {
  order: 1;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 16px;
}
@media (min-width: 480px) {
  .c-payment-methods__cta {
    order: 3;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 24px;
    margin-bottom: 0;
  }
}

.c-price-badge {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .c-price-badge {
    margin-bottom: 28px;
  }
}

.c-price-badge__price {
  color: #15A5BA;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2px;
}
@media (min-width: 480px) {
  .c-price-badge__price {
    font-size: 32px;
  }
}

.c-price-badge__unit {
  color: #A3A3A3;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 480px) {
  .c-price-badge__unit {
    font-size: 18px;
  }
}

.c-pricing-uon-logo {
  margin-bottom: 12px;
  max-width: 100px;
  opacity: 0.25;
}
@media (min-width: 480px) {
  .c-pricing-uon-logo {
    margin-bottom: 16px;
    max-width: 120px;
  }
}

.c-pricing-uon-logo-text {
  color: #a2a1a1;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 480px) {
  .c-pricing-uon-logo-text {
    font-size: 12px;
  }
}

.c-quick-nav {
  display: block;
  margin: auto;
  max-width: 280px;
}
@media (min-width: 768px) {
  .c-quick-nav {
    max-width: none;
  }
}

.c-quick-nav__link {
  display: block;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: none;
  width: auto;
}
@media (min-width: 768px) {
  .c-quick-nav__link {
    display: inline-block;
    margin: 0 16px 16px 0;
  }
}
@media (min-width: 1024px) {
  .c-quick-nav__link {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .c-quick-nav__link {
    font-size: 20px;
  }
}

.c-separator {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 16px 0;
}

.c-separator--content {
  margin: 16px 0 32px;
}

.c-separator--section {
  margin: 56px 0;
}

.c-section--grey-bg {
  background: #f3f3f3;
  padding-bottom: 40px;
  padding-top: 40px;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .c-section--grey-bg {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}
@media (min-width: 1024px) {
  .c-section--grey-bg {
    padding-bottom: 88px;
    padding-top: 88px;
  }
}

.u-section-margin--quick-nav {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .u-section-margin--quick-nav {
    margin-bottom: 56px;
  }
}
@media (min-width: 1024px) {
  .u-section-margin--quick-nav {
    margin-bottom: 80px;
  }
}

.c-site-wrapper--has-page-banner-image .u-section-margin--quick-nav {
  padding-top: 40px;
  margin-top: 32px;
}
@media (min-width: 768px) {
  .c-site-wrapper--has-page-banner-image .u-section-margin--quick-nav {
    padding-top: 80px;
    margin-top: 32px;
  }
}
@media (min-width: 1024px) {
  .c-site-wrapper--has-page-banner-image .u-section-margin--quick-nav {
    padding-top: 138px;
    margin-top: 0;
  }
}

.c-section__heading {
  font-size: 40px;
  line-height: 0.9;
  margin: 24px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .c-section__heading {
    font-size: 48px;
    margin-bottom: 12px;
  }
}
@media (min-width: 1024px) {
  .c-section__heading {
    font-size: 60px;
  }
}

.c-section__heading--more-margin {
  margin: 72px 0 32px;
}

.c-section__description {
  color: #4a4a4a;
  font-size: 20px;
  text-align: center;
  margin-bottom: 64px;
}

.c-secure-data {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}
@media (min-width: 768px) {
  .c-secure-data {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

.c-secure-data__img {
  max-width: 48px;
}
@media (min-width: 768px) {
  .c-secure-data__img {
    max-width: 56px;
  }
}
@media (min-width: 1024px) {
  .c-secure-data__img {
    max-width: 72px;
  }
}

.c-secure-data__text {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  margin-left: 16px;
  max-width: 380px;
  text-align: left;
}
@media (min-width: 480px) {
  .c-secure-data__text {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .c-secure-data__text {
    font-size: 15px;
    margin-left: 16px;
  }
}

.c-share-article {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .c-share-article {
    margin-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .c-share-article {
    display: block;
    position: absolute;
    top: 0;
    left: -90px;
    margin-bottom: 0;
  }
}

.c-share-article__label {
  font-size: 13px;
  font-weight: bold;
  margin-right: 8px;
}
@media (min-width: 1024px) {
  .c-share-article__label {
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.c-share-article__link {
  background-color: #eee;
  border: 1px solid #ddd;
  display: block;
  height: 40px;
  margin-right: 8px;
  width: 40px;
}
@media (min-width: 1024px) {
  .c-share-article__link {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.c-show-hide__default {
  display: inline;
}

.c-show-hide__alternative {
  display: none;
}

.c-show-hide.is-expanded .c-show-hide__default,
.c-show-hide.is-showing .c-show-hide__default,
.c-show-hide.is-visible .c-show-hide__default,
.c-show-hide.is-active .c-show-hide__default {
  display: none;
}
.c-show-hide.is-expanded .c-show-hide__alternative,
.c-show-hide.is-showing .c-show-hide__alternative,
.c-show-hide.is-visible .c-show-hide__alternative,
.c-show-hide.is-active .c-show-hide__alternative {
  display: inline;
}

.c-simple-article-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-decoration: none;
}

.c-simple-article-card__subtitle {
  text-transform: uppercase;
  font-size: 13px;
}

.c-simple-article-card__heading {
  font-size: 15px;
}

.c-simple-article-card__stats {
  text-align: right;
}

.c-simple-article-card__time {
  font-size: 14px;
}

.c-site-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-site-wrapper {
    display: block;
  }
}

.c-small {
  font-size: 12px;
}
@media (min-width: 768px) {
  .c-small {
    font-size: 14px;
  }
}

.c-social-sharing--margin {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .c-social-sharing--margin {
    margin-bottom: 32px;
  }
}

.c-social-sharing__title {
  color: #a7a7a7;
  display: inline-block;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-right: 8px;
}

.c-social-sharing__link {
  color: #15A5BA;
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-right: 12px;
  text-decoration: none;
  vertical-align: middle;
}
.c-social-sharing__link:hover, .c-social-sharing__link:focus {
  color: #15A5BA;
  opacity: 0.8;
  text-decoration: none;
}

.c-social-sharing__link--facebook {
  width: 18px;
}

.c-social-sharing__link--email {
  font-size: 20px;
}

/* ==========================================================================
   #STYLEGUIDE
   ========================================================================== */
.c-style-guide__intro {
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  border-right-width: 2px;
  margin: 2em 0 1em 0;
  padding: 2em;
}

.c-style-guide__title {
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0 0 0.25em 0;
  text-transform: uppercase;
}

.c-style-guide__desc {
  color: #666;
  margin: 0;
}

.c-style-guide__section {
  padding: 1rem 0;
}
.c-style-guide__section:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.c-style-guide__section-title {
  border-bottom: 1px solid #ccc;
  clear: both;
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  width: 50%;
}

.c-style-guide__section-subtitle {
  border-left: 6px solid #ccc;
  clear: both;
  color: #999;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 2em 0;
  padding-left: 1em;
  text-transform: uppercase;
}

.c-tabs-panels__tab {
  background-color: #eee;
  border: #ddd;
  border-radius: 20px;
  color: #333;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 8px 12px;
  line-height: 1;
  margin-right: 12px;
  margin-bottom: 12px;
  text-decoration: none;
  text-transform: uppercase;
}
.c-tabs-panels__tab.is-expanded {
  background-color: #15A5BA;
  color: #fff;
}

.c-tabs-panels__panel {
  margin-top: 24px;
}
.c-tabs-panels__panel.is-hidden {
  display: none;
}

.c-text-block {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .c-text-block {
    margin-bottom: 48px;
  }
}

.c-text-link {
  border: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  text-decoration: none;
}
.c-text-link:hover, .c-text-link:active, .c-text-link:focus {
  text-decoration: none;
}
.c-text-link:hover, .c-text-link:active {
  opacity: 0.8;
}

.c-text-link__icon {
  font-size: 10px;
}

.c-text-link__text {
  text-decoration: underline;
}

.c-text-link--danger {
  color: red;
}

.c-text-link--primary {
  color: blue;
}

.c-use-case {
  outline: 0;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .c-use-case {
    margin-top: 72px;
  }
}
@media (min-width: 1024px) {
  .c-use-case {
    margin-top: 140px;
  }
}

.c-use-case + .c-use-case {
  margin-top: 50px;
}
@media (min-width: 1024px) {
  .c-use-case + .c-use-case {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  .c-use-case + .c-use-case .c-use-case__separator {
    border-top: 1px solid #CACACA;
    padding-top: 64px;
  }
}
@media (min-width: 1024px) {
  .c-use-case + .c-use-case .c-use-case__separator {
    padding-top: 90px;
  }
}

.c-use-case__intro {
  text-align: center;
}
@media (min-width: 768px) {
  .c-use-case__intro {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    text-align: left;
  }
}

.c-use-case__heading {
  color: #313131;
  font-size: 18px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .c-use-case__heading {
    font-size: 24px;
  }
}
@media (min-width: 1280px) {
  .c-use-case__heading {
    font-size: 30px;
  }
}

.c-use-case__subheading {
  font-size: 15px;
}
@media (min-width: 768px) {
  .c-use-case__subheading {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.c-use-case__image {
  margin-bottom: 20px;
  max-width: 74px;
}
@media (min-width: 768px) {
  .c-use-case__image {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .c-use-case__image {
    margin-right: 30px;
    max-width: 122px;
  }
}

.c-use-case__summary {
  line-height: 1.8;
}

.c-site-bg {
  background: #eee;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-align-flex-end {
  align-items: flex-end;
}

.u-align-center {
  align-items: center;
}

.u-space-between {
  justify-content: space-between;
}

.u-justify-center {
  justify-content: center;
}

.u-case--lower {
  text-transform: lowercase;
}

.u-case--upper {
  text-transform: uppercase;
}

.u-case--reset {
  text-transform: none;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill {
  background: #ccc;
}

.u-color-white {
  color: #fff;
}

.u-color-charcoal {
  color: #313131;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: none !important;
  }
}

.u-visible\@medium {
  display: none;
}
@media (min-width: 768px) {
  .u-visible\@medium {
    display: block;
  }
}

.u-visible\@large {
  display: none;
}
@media (min-width: 1024px) {
  .u-visible\@large {
    display: block;
  }
}

.u-visible\@xlarge {
  display: none;
}
@media (min-width: 1280px) {
  .u-visible\@xlarge {
    display: block;
  }
}

[v-cloak] {
  display: none;
}

html.u-lock-scroll,
html.u-lock-scroll body {
  overflow-y: hidden;
}

@media (max-width: 1023px) {
  .sidebar-visible,
  .sidebar-visible body {
    overflow-y: hidden;
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-display-block {
  display: block;
}

.u-margin {
  margin: 1rem;
}

.u-margin-top {
  margin-top: 1rem;
}

.u-margin-top-2 {
  margin-top: 2rem;
}

.u-margin-top-3 {
  margin-top: 3rem;
}

.u-margin-right {
  margin-right: 1rem;
}

.u-margin-right-2 {
  margin-right: 2rem;
}

.u-margin-right-3 {
  margin-right: 3rem;
}

.u-margin-bottom {
  margin-bottom: 1rem;
}

.u-margin-bottom-2 {
  margin-bottom: 2rem;
}

.u-margin-bottom-3 {
  margin-bottom: 3rem;
}

.u-margin-left {
  margin-left: 1rem;
}

.u-margin-left-2 {
  margin-left: 2rem;
}

.u-margin-left-3 {
  margin-left: 3rem;
}

.u-padding {
  padding: 1rem;
}

.u-padding-top {
  padding-top: 1rem;
}

.u-padding-right {
  padding-right: 1rem;
}

.u-padding-bottom {
  padding-bottom: 1rem;
}

.u-padding-left {
  padding-left: 1rem;
}

.u-margin-bottom-0 {
  margin-bottom: 0;
}

.u-section-margin {
  margin-bottom: 24px;
  margin-top: 24px;
}

.u-section-margin--medium {
  margin-bottom: 32px;
  margin-top: 32px;
}
@media (min-width: 768px) {
  .u-section-margin--medium {
    margin-bottom: 56px;
    margin-top: 56px;
  }
}
@media (min-width: 1024px) {
  .u-section-margin--medium {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}

.u-section-margin--large {
  margin-bottom: 56px;
  margin-top: 56px;
}
@media (min-width: 768px) {
  .u-section-margin--large {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}
@media (min-width: 1024px) {
  .u-section-margin--large {
    margin-bottom: 120px;
    margin-top: 120px;
  }
}
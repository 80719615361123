.c-secure-data {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 28px;

  @include bp-medium {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

.c-secure-data__img {
  max-width: 48px;

  @include bp-medium {
    max-width: 56px;
  }

  @include bp-large {
    max-width: 72px;
  }
}

.c-secure-data__text {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  margin-left: 16px;
  max-width: 380px;
  text-align: left;

  @include bp-small {
    font-size: 14px;
  }

  @include bp-medium {
    font-size: 15px;
    margin-left: 16px;
  }
}

/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */

/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */

.o-table--fixed {
  table-layout: fixed;
}

table {
  th {
    background-color: $color-light-beige;
    border: 1px solid #000;
    font-weight: bold;
    min-width: 190px;
    padding: 12px 16px;
    vertical-align: middle;
  }

  td {
    border: 1px solid #000;
    padding: 12px 16px;
  }

  tbody tr {
    vertical-align: top;
  }
}

.c-table-wrapper {
  margin-left: -16px;
  margin-right: 16px;
  overflow: scroll;
  padding: 0 16px;
  width: calc(100% + 32px);
}

.c-pricing-table {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  margin: 0 auto 40px;
  max-width: 724px;

  @include bp-small {
    font-size: 14px;
    text-align: left;
  }

  @include bp-medium {
    font-size: 17px;
  }

  th {
    min-width: 0;
  }

  th, td {
    border-width: 0;
    padding: 12px 10px;

    @include bp-small {
      padding: 12px 16px;
    }

    @include bp-medium {
      padding: 12px 24px;
    }
  }

  td {
    background: #fff;
  }

  th {
    background: #15A5BA;
    color: #fff;
    font-weight: bold;
  }

  tr:nth-child(odd) td {
    background: #FAFAFA;
  }

  tr td:first-child {
    background: #91DCE9;
    font-weight: bold;
  }
}

.c-pricing-table__col-qty {
  @include bp-medium {
    width: 21%;
  }
}

.c-pricing-table__col-unit {
  @include bp-medium {
    width: 25%;
  }
}

.c-pricing-table__col-gst {
  @include bp-medium {
    width: 21%;
  }
}

.c-pricing-table__col-price {
  @include bp-medium {
    width: 33%;
  }
}

.c-pricing-table__row-break {
  display: block;
  height: 0;

  @include bp-medium {
    display: none;
  }
}


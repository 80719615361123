.c-page-banner-wrapper {
  position: relative;
}

.c-page-banner {
  background-color: $color-charcoal;
  display: flex;
  flex-direction: column;
  min-height: 300px;

  @include bp-medium {
    min-height: 380px;
  }

  .c-page-banner-wrapper--home & {
    background-color: transparent;
    display: block;

    @include bp-large {
      background-color: $color-charcoal;
    }
  }
}

.c-page-banner__img {
  background-color: $color-charcoal;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  max-height: 400px;
  position: absolute;
  right: 0;
  top: 0;

  @include bp-medium {
    min-height: 380px;
  }

  @include bp-large {
    max-height: none;
  }
}

.c-page-banner-wrapper--home {
  padding-bottom: 0;
  margin-top: 40px;

  @include bp-large {
    margin-top: 48px;
  }

  .c-page-banner {
    min-height: 360px;

    @include bp-medium {
      min-height: 400px;
    }

    @include bp-large {
      min-height: 480px;
    }
  }
}

.c-page-banner__picture {
  display: none;
}

.c-page-banner__image {
  display: block;
  width: 100%;
}

.c-page-banner__logo {
  @include bp-large {
    height: 110px;
  }
}

.c-page-banner__logo-wrapper {
  margin: 0 auto;
  position: relative;
  width: 80px;

  @include bp-medium {
    width: 104px;
  }

  @include bp-large {
    display: none;
  }
}

.c-page-banner__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  line-height: 1.35;
  margin: 0 auto;
  min-height: 300px;
  padding-bottom: 24px;
  padding-top: 104px;
  position: relative;
  text-align: center;
  z-index: 1;

  @include bp-medium {
    min-height: 380px;
  }

  @include bp-large {
    padding-bottom: 56px;
    padding-top: 144px;
    width: 100%;
  }
}

.c-page-banner__content--home {
  display: block;

  @include bp-small {
    padding-top: 124px;
  }

  @include bp-medium {
    display: flex;
  }

  @include bp-large {
    padding-top: 94px;
    padding-bottom: 0;
  }
}

.c-page-banner__copy {
  color: $color-white;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 auto;
  max-width: 800px;
  text-shadow: 0 0 20px #000, 0 0 20px #000;
  width: 100%;

  * {
    color: $color-white;
  }

  @include bp-xsmall {
    font-size: 15px;
  }

  @include bp-medium {
    font-size: 18px;
    margin-bottom: 24px;
  }

  h1, h2, h3, h4, h6, h6 {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 16px;

    @include bp-medium {
      font-size: 22px;
    }

    @include bp-xlarge {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }
}

.c-page-banner__cta {
  box-shadow: $global-btn-shadow;
  display: inline-block;
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 32px;
  }
}

.c-page-banner__grid {
  @include bp-medium {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @include bp-large {
    flex-direction: row;
    flex-shrink: 0;
    transform: translateY(62px);
  }

  .c-page-banner__content-image {
    background: white;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    order: 2;

    @include bp-large {
      order: 1;
      width: calc(50% - 42px);
    }

    img {
      width: 100%;
    }
  }

  .c-page-banner__copy {
    order: 1;

    @include bp-large {
      margin-left: 42px;
      order: 2;
      text-align: left;
      transform: translateY(-12px);
      width: 50%;
    }

    @include bp-xlarge {
      transform: translateY(-40px);
    }
  }
}

.c-page-banner__content-image-caption {
  color: #a2a1a1;
  font-size: 11px;
  padding: 8px 12px 8px;

  @include bp-small {
    font-size: 12px;
    padding: 4px 16px 8px;
  }

  @include bp-medium {
    padding: 0 16px 8px;
  }

  @include bp-large {
    font-size: 13px;
  }
}

.c-price-badge {
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 28px;
  }
}

.c-price-badge__price {
  color: $color-blue;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2px;

  @include bp-small {
    font-size: 32px;
  }
}

.c-price-badge__unit {
  color: #A3A3A3;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;

  @include bp-small {
    font-size: 18px;
  }
}

/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  @include bp-medium {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.o-grid--no-wrap {
  flex-wrap: nowrap;
}

.o-grid--scroll {
  overflow-x: scroll;

  .o-inner-wrapper & {
    @include bp($max, $bp-medium - 1px) {
      margin-left: -8px;
      margin-right: -16px;
    }
  }
}

.o-grid--reverse-at-medium {
  @include bp-medium {
    flex-direction: row-reverse;
  }
}

.o-grid--small--gutter {
  margin-left: -12px;
  margin-right: -12px;

  @include bp-medium {
    margin-left: -24px;
    margin-right: -24px;
  }
}


.o-grid--medium--gutter {
  margin-left: -16px;
  margin-right: -16px;

  @include bp-medium {
    margin-left: -32px;
    margin-right: -32px;
  }
}

.o-grid__cell {
  padding: 0 8px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;

  @include bp-medium {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}

.o-grid--no-mb--large .o-grid__cell {
  @include bp-large {
    margin-bottom: 0;
  }
}

.o-grid__cell--small-gutter {
  padding: 0 12px;

  @include bp-medium {
    padding: 0 24px;
  }
}

.o-grid__cell--medium-gutter {
  padding: 0 16px;

  @include bp-medium {
    padding: 0 32px;
  }
}

.o-grid__cell--wide-gutter {
  padding: 0 24px;
  
  @include bp-medium {
    padding: 0 32px;
  }
}

.o-grid__cell--no-flex {
  display: block;
}

.o-grid__cell--left-copy {
  @include bp-large {
    padding-right: 0;
  }
}

.o-grid__cell--right-copy {
  @include bp-large {
    padding-left: 0;
  }
}

.o-grid__cell--20 {
  flex-basis: 20%;
  max-width: 20%;
}

.o-grid__cell--25 {
  flex-basis: 25%;
  max-width: 25%;
}

.o-grid__cell--33 {
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.o-grid__cell--40 {
  flex-basis: 40%;
  max-width: 40%;
}

.o-grid__cell--45 {
  flex-basis: 45%;
  max-width: 45%;
}

.o-grid__cell--50 {
  flex-basis: 50%;
  max-width: 50%;
}

.o-grid__cell--55 {
  flex-basis: 55%;
  max-width: 55%;
}

.o-grid__cell--60 {
  flex-basis: 60%;
  max-width: 60%;
}

.o-grid__cell--66 {
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.o-grid__cell--75 {
  flex-basis: 75%;
  max-width: 75%;
}

.o-grid__cell--100 {
  flex-basis: 100%;
  max-width: 100%;
}

@mixin column-builder($breakpoint: null) {
  .o-grid__cell--20-at-#{$breakpoint} {
    flex-basis: 20%;
    max-width: 20%;
  }

  .o-grid__cell--25-at-#{$breakpoint} {
    flex-basis: 25%;
    max-width: 25%;
  }

  .o-grid__cell--33-at-#{$breakpoint} {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .o-grid__cell--40-at-#{$breakpoint} {
    flex-basis: 40%;
    max-width: 40%;
  }

  .o-grid__cell--45-at-#{$breakpoint} {
    flex-basis: 45%;
    max-width: 45%;
  }

  .o-grid__cell--50-at-#{$breakpoint} {
    flex-basis: 50%;
    max-width: 50%;
  }

  .o-grid__cell--55-at-#{$breakpoint} {
    flex-basis: 55%;
    max-width: 55%;
  }

  .o-grid__cell--60-at-#{$breakpoint} {
    flex-basis: 60%;
    max-width: 60%;
  }

  .o-grid__cell--66-at-#{$breakpoint} {
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }

  .o-grid__cell--75-at-#{$breakpoint} {
    flex-basis: 75%;
    max-width: 75%;
  }

  .o-grid__cell--100-at-#{$breakpoint} {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@include bp-small {
  @include column-builder('small')
}

@include bp-medium {
  @include column-builder('medium')
}

@include bp-large {
  @include column-builder('large')
}

@include bp-xlarge {
  @include column-builder('xlarge')
}

@include bp-full {
  @include column-builder('full')
}

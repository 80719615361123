/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.5rem;
}

address,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  font-size: 0.9375em;

  @include bp-small {
    font-size: 1em;
  }

  @include bp-large {
    font-size: 1.0625em;
  }

  p {
    font-size: inherit;
  }
}

/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: 1rem;
}

.c-text-link {
  border: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
}

.c-text-link__icon {
  font-size: 10px;
}

.c-text-link__text {
  text-decoration: underline;
}

.c-text-link--danger {
  color: red;
}

.c-text-link--primary {
  color: blue;
}

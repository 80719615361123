html.u-lock-scroll,
html.u-lock-scroll body {
  overflow-y: hidden;
}

.sidebar-visible,
.sidebar-visible body {
  @include bp($max, $bp-large - 1px) {
    overflow-y: hidden;
  }
}
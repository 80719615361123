.features-and-pricing table {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;
}

.features-and-pricing table td, .features-and-pricing table th {
  border: 0;
}

.features-and-pricing thead th {
  background-color: #999;
  color: #fff;
}
.features-and-pricing thead td {
  text-align: center;
  vertical-align: top;
}
.features-and-pricing thead th b {
  font-size: 1.2em;
}
.features-and-pricing thead td small {
  display: block;
}

.features-and-pricing tbody th {
  text-align: left;
  max-width: 190px;
}

.features-and-pricing tbody td {
  text-align: center;
}

.features-and-pricing tbody td.col-disabled {
  vertical-align: center;
}

.features-and-pricing tbody tr:nth-child(odd) td {
  background: #fafafa;
}

.features-and-pricing abbr {
  text-decoration: none;
}

.features-and-pricing abbr.on {
  color: green;
}

.features-and-pricing abbr.off {
  color: red;
  opacity: 0.4;
  font-size: 0.6em;
}

.features-and-pricing tfoot td {
  background-color: #657786;
  color: #fff;
  text-align: center;
}
.features-and-pricing tfoot td p {
  text-align: center;
}
.features-and-pricing tfoot td a {
  color: #fff;
}

.features-and-pricing fieldset.price-config {
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 1em auto 1em auto;
}

.features-and-pricing fieldset.price-config legend {
  text-align: center;
  width: auto;
  font-weight: bold;
}

/* FF only */
@-moz-document url-prefix() {
  .features-and-pricing fieldset.price-config {
    position: relative;
  }
  .features-and-pricing fieldset.price-config legend {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.features-and-pricing fieldset.price-config label {
  margin: 0.5em auto 0.5em auto;
}

.features-and-pricing fieldset.price-config input[type="range"] {
  text-align: center;
  width: 100%;
  display: block;
  margin: 0.5em auto 0.5em auto;
}

.features-and-pricing fieldset.price-config input[type="number"] {
  text-align: center;
  display: block;
  margin: 0.5em auto 0 auto;
  font-size: 2em;
  font-weight: bold;
  border: none;
  width: 100%;
}
.features-and-pricing fieldset.price-config input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.features-and-pricing .c-accordion__trigger {
  padding: 0 28px 0 0;
}
.features-and-pricing .c-accordion__trigger:before {
  right: 0;
}
.features-and-pricing .c-accordion__trigger:after {
  display: none;
}

.features-and-pricing .c-accordion__panel {
  padding: 8px 0 0 0;
}

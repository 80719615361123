.c-quick-nav {
  display: block;
  margin: auto;
  max-width: 280px;

  @include bp-medium {
    max-width: none;
  }
}

.c-quick-nav__link {
  display: block;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: none;
  width: auto;

  @include bp-medium {
    display: inline-block;
    margin: 0 16px 16px 0;
  }

  @include bp-large {
    font-size: 18px;
  }

  @include bp-xlarge {
    font-size: 20px;
  }
}

.c-payment-methods {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 32px;

  @include bp-small {
    flex-direction: row;
  }

  @include bp-medium {
    margin-bottom: 28px;
  }

  @include bp-large {
    margin-bottom: 40px;
  }
}

.c-payment-methods__text {
  order: 2;
  color: #A3A3A3;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;

  @include bp-small {
    order: 1;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.c-payment-methods__icons {
  order: 3;

  @include bp-small {
    order: 2;
  }
}

.c-payment-methods__method {
  color: #A19FA0;
  font-size: 28px;
  margin: 0 2px;

  @include bp-medium {
    font-size: 36px;
  }
}

.c-payment-methods__cta {
  order: 1;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 16px;

  @include bp-small {
    order: 3;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 24px;
    margin-bottom: 0;
  }
}

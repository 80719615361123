/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1800px);
}

.o-inner-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px + 48px);
  padding-left: 16px;
  padding-right: 16px;

  @include bp-medium {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.o-inner-wrapper--narrower {
  max-width: calc(900px + 48px);
}

.c-footer-navigation {
  text-align: center;

  @include bp-xsmall {
    text-align: left;
  }

  @include bp-large {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  li {
    display: block;
    margin-bottom: 12px;

    @include bp-xsmall {
      float: left;
      width: 50%;
    }

    @include bp-medium {
      display: inline-block;
      float: none;
      margin-right: 20px;
      width: auto;
    }

    @include bp-xlarge {
      margin-right: 40px;
    }
  }

  li a {
    color: #9b9b9b;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;

    @include bp-xsmall {
      font-size: 13px;
    }

    @include bp-small {
      font-size: 17px;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
      text-decoration: none;
    }
  }
}

.c-footer-navigation__pages {
  @include clearfix;

  @include bp-medium {
    margin-bottom: 0;
  }
}

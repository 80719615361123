.c-btn-row {
  display: block;
  margin-bottom: 24px;

  @include bp-medium {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.c-btn-row__btn {
  margin-bottom: 32px !important;

  @include bp-medium {
    margin-bottom: 0 !important;
  }
}

.c-btn-row__image {
  max-height: 40px;
  margin-left: 16px;

  @include bp-medium {
    max-height: 50px;
  }

  @include bp-large {
    margin-left: 24px;
  }
}
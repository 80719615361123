.c-benefit {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 16px;
  max-width: 280px;
  width: 100%;

  @include bp-medium {
    max-width: none;
  }

  @include bp-medium {
    justify-content: flex-start;
    margin-left: 0;
  }

  @include bp-large {
    margin-bottom: 40px;
  }
}

.c-benefit--light {
  @include bp-medium {
    max-width: 300px;
  }
}

.c-benefit__icon {
  color: $color-blue;
  display: block;
  flex-shrink: 0;
  font-size: 28px;
  line-height: 1;
  margin-right: 16px;
  text-align: center;
  width: 30px;

  @include bp-medium {
    font-size: 40px;
    width: 50px;
  }

  .c-benefit--light & {
    @include bp-medium {
      font-size: 34px;
      width: 40px;
    }
  }
}

.c-benefit__heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  line-height: 1.2;

  @include bp-medium {
    font-size: 16px;
  }

  @include bp-xlarge {
    font-size: 18px;
  }

  .c-benefit--light & {
    font-weight: 400;
  }
}
